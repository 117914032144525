import { FilterGroup } from 'components/Filters/components/FilterModal'
import { SelectFilter } from 'components/Filters/fields/SelectFilter'
import { FilterStore } from 'components/Filters/FilterStore'
import {
  RangeFilter,
  RangeFilterWithMinMax,
} from 'components/Filters/fields/RangeFilter'
import {
  createFilterColumnHelper,
  DataTypeColumn,
} from 'components/DataExpansionComponents/Common/commonColumnHelpers'
import { GooglePlaceDoorBase } from 'models/google_place_door'
import { ZipcodeFilter } from 'components/Filters/fields/ZipcodeFilter'
import { featureFlagService } from 'utils/featureFlagService'
import { ALL_COUNTRIES } from 'utils/countries'
import { CityAndStateFilter } from 'components/Filters/components/CityAndStateFilter'

export function CommonFiltersetGooglePlaceDoorGroup({
  filterStore,
  columns,
}: {
  filterStore: FilterStore
  columns: Record<keyof GooglePlaceDoorBase, DataTypeColumn>
}) {
  const featureFlags = featureFlagService()
  const commonGooglePlaceFilterHelper = createFilterColumnHelper({
    columns,
  })

  return (
    <>
      <FilterGroup title="Opportunity Attributes">
        <RangeFilterWithMinMax
          {...commonGooglePlaceFilterHelper.retrieveProps('reviews_avg')}
          store={filterStore}
          min={0}
          max={5}
          decimalScale={2}
        />
        <RangeFilter
          {...commonGooglePlaceFilterHelper.retrieveProps('reviews_cnt')}
          store={filterStore}
        />
        <RangeFilter
          {...commonGooglePlaceFilterHelper.retrieveProps('chain_doors_cnt')}
          store={filterStore}
        />
      </FilterGroup>

      <FilterGroup title="Location">
        {featureFlags.enableRestaurantsCountryFilter && (
          <SelectFilter
            {...commonGooglePlaceFilterHelper.retrieveProps('country')}
            options={ALL_COUNTRIES}
            filterStore={filterStore}
          />
        )}
        <CityAndStateFilter
          filterStore={filterStore}
          stateSelectProps={commonGooglePlaceFilterHelper.retrieveProps(
            'state'
          )}
          citySelectProps={commonGooglePlaceFilterHelper.retrieveProps('city')}
        />
        <ZipcodeFilter filterStore={filterStore} />
      </FilterGroup>
    </>
  )
}
