import { create } from 'zustand'

interface NavbarStoreState {
  lastOppVisited: string
  setLastOppVisited: (url: string) => void
  lastContactVisited: string
  setLastContactVisited: (url: string) => void
  lastActivityVisited: string
  setLastActivityVisited: (url: string) => void
  lastSettingsVisited: string
  setLastSettingsVisited: (url: string) => void
  lastRebateVisited: string
  setLastRebateVisited: (url: string) => void
  lastCampaignTabVisited: Record<string, string>
  setLastCampaignTabVisited: (campaignId: string, url: string) => void
}

export const useNavbarStore = create<NavbarStoreState>()((set) => ({
  lastOppVisited: '/opportunities/',
  setLastOppVisited: (by) => set((state) => ({ ...state, lastOppVisited: by })),
  lastContactVisited: '/contacts/',
  setLastContactVisited: (by) =>
    set((state) => ({ ...state, lastContactVisited: by })),
  lastActivityVisited: '/activity/',
  setLastActivityVisited: (by) =>
    set((state) => ({ ...state, lastActivityVisited: by })),
  lastSettingsVisited: '/settings/',
  setLastSettingsVisited: (by) =>
    set((state) => ({ ...state, lastSettingsVisited: by })),
  lastRebateVisited: '/rebates/',
  setLastRebateVisited: (by) =>
    set((state) => ({ ...state, lastRebateVisited: by })),
  lastCampaignTabVisited: {},
  setLastCampaignTabVisited: (campaignId, url) =>
    set((state) => ({
      lastCampaignTabVisited: {
        ...state.lastCampaignTabVisited,
        [campaignId]: url,
      },
    })),
}))
