import { createColumnHelper } from '@tanstack/react-table'
import { createTableColumnHelper } from 'components/DataExpansionComponents/Common/commonColumnHelpers'
import { EmailValidityPill } from 'components/FbUI/EmailValidityPill'
import { FbLink } from 'components/FbUI/FbLink'
import { JsonIntoUl } from 'components/json-into-ul'
import { usePreferences } from 'context/preferences/PreferencesContext'
import {
  ContactResponse,
  ContactSource,
  ContactSourceLabels,
  Department,
  DepartmentLabels,
  Seniority,
  SeniorityLabels,
} from 'models/contacts'
import { useMemo } from 'react'
import { IoIosWarning } from 'react-icons/io'
import { MdOutlineEmail } from 'react-icons/md'
import styled from 'styled-components'
import { formatInteger } from 'utils/formatting'
import { getCompanyUrl } from 'utils/getCompanyUrl'
import { getPathFromUrl } from 'utils/getPathFromUrl'
import { ContactStarToggle } from '../../ContactStarToggle'
import { ConditionalTooltipWrapper } from '../../FbUI/ConditionalTooltipWrapper'
import {
  AccountOwnerCell,
  CampaignsCell,
  SaleStagesCellFromDeals,
  TaglistCell,
} from '../../FbUI/StagePill'
import { Anchor } from '../../UI/Anchor'
import { CONTACTS_COLUMNS } from './contactsColumnInfo'
import { PhoneCell } from '../CommonTableCells/PhoneCell'
export const useContactsTableColumns = () => {
  const { preferences } = usePreferences()
  const tooltips: Record<string, unknown> = preferences?.tooltips || {}

  const columnHelper = createColumnHelper<ContactResponse>()
  const contactColumnHelper = createTableColumnHelper({
    columns: CONTACTS_COLUMNS,
    tooltips,
  })

  const cols = useMemo(
    () => [
      columnHelper.display({
        ...contactColumnHelper.retrieveColumn('full_name', {
          meta: {
            isEditable: false,
            isFirstColumn: true,
          },
          header: null,
        }),
        size: 250,
        cell: (info) => {
          const row = info.row.original

          return (
            <ContactStarToggle
              contactId={row.id}
              contactName={`${row.first_name} ${row.last_name}`}
              initialStarred={row.starred}
            />
          )
        },
      }),
      columnHelper.accessor('first_name', {
        ...contactColumnHelper.retrieveColumn('first_name'),
        size: 250,
        cell: (info) => {
          const firstName = info.getValue()
          if (!firstName) return '-'

          return firstName
        },
      }),
      columnHelper.accessor('last_name', {
        ...contactColumnHelper.retrieveColumn('last_name'),
        size: 250,
        cell: (info) => {
          const lastName = info.getValue()
          if (!lastName) return '-'

          return lastName
        },
      }),
      columnHelper.accessor('email_preferred', {
        ...contactColumnHelper.retrieveColumn('email_preferred'),
        size: 300,
        cell: (info) => {
          const email = info.getValue()
          if (!email) return '-'

          return (
            <div className={'flex items-center'}>
              <MdOutlineEmail
                size={20}
                color="#D0D3DD"
                style={{ marginRight: 5 }}
              />
              {email}
            </div>
          )
        },
      }),
      columnHelper.accessor('email_work', {
        ...contactColumnHelper.retrieveColumn('email_work'),
        size: 300,
        cell: (info) => {
          const email = info.getValue()
          if (!email) return '-'

          return (
            <div className={'flex items-center'}>
              <MdOutlineEmail
                size={20}
                color="#D0D3DD"
                style={{ marginRight: 5 }}
              />
              {email}
            </div>
          )
        },
      }),
      columnHelper.accessor('email_personal', {
        ...contactColumnHelper.retrieveColumn('email_personal'),
        size: 300,
        cell: (info) => {
          const email = info.getValue()
          if (!email) return '-'

          return (
            <div className={'flex items-center'}>
              <MdOutlineEmail
                size={20}
                color="#D0D3DD"
                style={{ marginRight: 5 }}
              />
              {email}
            </div>
          )
        },
      }),
      columnHelper.accessor('email_generic', {
        ...contactColumnHelper.retrieveColumn('email_generic'),
        size: 300,
        cell: (info) => {
          const email = info.getValue()
          if (!email) return '-'

          return (
            <div className={'flex items-center'}>
              <MdOutlineEmail
                size={20}
                color="#D0D3DD"
                style={{ marginRight: 5 }}
              />
              {email}
            </div>
          )
        },
      }),
      columnHelper.accessor('email_work_validity', {
        ...contactColumnHelper.retrieveColumn('email_work_validity'),
        size: 175,
        cell: (info) => {
          const value = info.getValue()
          return value !== null && value !== undefined ? (
            <EmailValidityPill emailValidity={value} />
          ) : (
            '-'
          )
        },
      }),
      columnHelper.accessor('email_personal_validity', {
        ...contactColumnHelper.retrieveColumn('email_personal_validity'),
        size: 175,
        cell: (info) => {
          const value = info.getValue()
          return value !== null && value !== undefined ? (
            <EmailValidityPill emailValidity={value} />
          ) : (
            '-'
          )
        },
      }),
      columnHelper.accessor('email_generic_validity', {
        ...contactColumnHelper.retrieveColumn('email_generic_validity'),
        size: 175,
        cell: (info) => {
          const value = info.getValue()
          return value !== null && value !== undefined ? (
            <EmailValidityPill emailValidity={value} />
          ) : (
            '-'
          )
        },
      }),
      columnHelper.accessor('contact_company', {
        ...contactColumnHelper.retrieveColumn('contact_company'),
        size: 250,
        cell: (info) => {
          const company = info.getValue()
          if (!company)
            return (
              <MissingBadge>
                <IoIosWarning size={18} color="#F55800" />
                Missing
              </MissingBadge>
            )

          const companyUrl = getCompanyUrl(info.row.original.contact_company)
          if (companyUrl) {
            return (
              <FbLink to={companyUrl} target="_blank">
                {company.name}
              </FbLink>
            )
          }

          return company.name
        },
      }),
      columnHelper.accessor('title', {
        ...contactColumnHelper.retrieveColumn('title'),
        size: 200,
      }),
      columnHelper.accessor('seniority', {
        ...contactColumnHelper.retrieveColumn('seniority'),
        size: 200,
        cell: (info) => {
          const value = info.getValue()
          if (value === null || value === undefined) return '-'

          return SeniorityLabels[value as Seniority]
        },
      }),
      columnHelper.accessor('department', {
        ...contactColumnHelper.retrieveColumn('department'),
        size: 200,
        cell: (info) => {
          const value = info.getValue()
          if (value === null || value === undefined) return '-'

          return DepartmentLabels[value as Department]
        },
      }),
      columnHelper.accessor('notes', {
        ...contactColumnHelper.retrieveColumn('notes'),
        size: 350,
        cell: (info) => {
          const notes = info.getValue()
          if (!notes) return '-'

          return (
            <ConditionalTooltipWrapper
              shouldWrap={notes.length > 50}
              tooltipText={notes}
            >
              <JsonIntoUl
                value={notes}
                fallback={
                  <span style={{ wordBreak: 'break-word' }}>
                    {`${notes.slice(0, 50)}${notes.length > 50 ? '...' : ''}`}
                  </span>
                }
              />
            </ConditionalTooltipWrapper>
          )
        },
      }),
      columnHelper.accessor('url', {
        ...contactColumnHelper.retrieveColumn('url'),
        size: 350,
        cell: (info) => {
          if (!info.getValue()) {
            return '-'
          }
          return (
            <Anchor href={info.getValue()} target="_blank">
              {info.getValue()}
            </Anchor>
          )
        },
      }),
      columnHelper.accessor('linkedin_url', {
        ...contactColumnHelper.retrieveColumn('linkedin_url'),
        size: 350,
        cell: (info) => {
          const value = info.getValue()
          if (!value) {
            return '-'
          }
          const url = value.startsWith('http') ? value : `https://${value}`
          return (
            <Anchor href={url} target="_blank">
              {value}
            </Anchor>
          )
        },
      }),
      columnHelper.accessor('phone_number', {
        ...contactColumnHelper.retrieveColumn('phone_number'),
        size: 225,
        cell: (info) => {
          const phone = info.getValue()
          const extension = info.row.original.extension
          return <PhoneCell phoneNumber={phone} extension={extension} />
        },
      }),
      columnHelper.display({
        ...contactColumnHelper.retrieveColumn('origin_campaign'),
        size: 225,
        cell: (info) => {
          const campaigns = []
          if (info.row.original.requested_contact?.job.campaign) {
            campaigns.push(info.row.original.requested_contact?.job.campaign)
          }
          return <CampaignsCell campaigns={campaigns} />
        },
      }),
      columnHelper.accessor('contact_company.deal_count', {
        ...contactColumnHelper.retrieveColumn('deal_count'),
        size: 110,
        cell: (info) => {
          return info.row.original.contact_company.deal_count || '-'
        },
      }),
      columnHelper.accessor('contact_company.deals', {
        ...contactColumnHelper.retrieveColumn('sales_stages'),
        size: 260,
        cell: (info) => {
          return (
            <SaleStagesCellFromDeals
              deals={info.row.original?.contact_company?.deals}
            />
          )
        },
      }),
      columnHelper.accessor('contact_company.deals', {
        ...contactColumnHelper.retrieveColumn('account_owners'),
        size: 260,
        cell: (info) => {
          const deals = info.row.original?.contact_company?.deals
          return <AccountOwnerCell deals={deals} />
        },
      }),
      columnHelper.accessor('tags', {
        ...contactColumnHelper.retrieveColumn('contact_labels'),
        size: 260,
        cell: (info) => {
          return <TaglistCell taglist={info.getValue()} />
        },
      }),
      columnHelper.accessor('contact_company.taglist', {
        ...contactColumnHelper.retrieveColumn('company_tags'),
        size: 260,
        cell: (info) => {
          return <TaglistCell taglist={info.getValue()} />
        },
      }),
      columnHelper.accessor('company_size', {
        ...contactColumnHelper.retrieveColumn('company_size'),
        size: 200,
        cell: (info) => {
          const value = info.getValue()
          return value ? formatInteger(value) : '-'
        },
      }),
      columnHelper.accessor('company_chain_instagram_handle', {
        ...contactColumnHelper.retrieveColumn('company_chain_instagram_handle'),
        size: 200,
        cell: (info) => {
          const value = info.getValue()
          if (!value) {
            return '-'
          }

          return (
            <Anchor href={value} target="_blank">
              @{getPathFromUrl(value)}
            </Anchor>
          )
        },
      }),
      columnHelper.accessor('company_cuisine_50', {
        ...contactColumnHelper.retrieveColumn('company_cuisine_50'),
        size: 200,
        cell: (info) => {
          return info.getValue() || '-'
        },
      }),
      columnHelper.accessor('source', {
        ...contactColumnHelper.retrieveColumn('source'),
        size: 125,
        cell: (info) => {
          const value = info.getValue()
          if (value === null || value === undefined) return '-'

          return ContactSourceLabels[value as ContactSource]
        },
      }),
      columnHelper.accessor('created', {
        ...contactColumnHelper.retrieveColumn('created'),
        size: 125,
        cell: (info) => {
          const date = info.getValue()
          if (!date) return '-'

          return new Date(date).toLocaleDateString()
        },
      }),
      columnHelper.accessor('modified', {
        ...contactColumnHelper.retrieveColumn('modified'),
        size: 125,
        cell: (info) => {
          const date = info.getValue()
          if (!date) return '-'

          return new Date(date).toLocaleDateString()
        },
      }),
    ],
    [preferences, tooltips]
  )

  return cols
}

export const MissingBadge = styled.div`
  display: inline-flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  background-color: #ffefef;
  padding: 2px 8px;
  border-radius: 16px;
  mix-blend-mode: multiply;
  color: #d31717;
`
