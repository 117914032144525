import { createColumnHelper } from '@tanstack/react-table'
import { getCommonContactCompanyColumns } from 'components/DataExpansionComponents/Common/CommonTable/CommonColumns'
import { Anchor } from 'components/UI/Anchor'
import { usePreferences } from 'context/preferences/PreferencesContext'
import { useMemo } from 'react'
import { featureFlagService } from 'utils/featureFlagService'
import { integerFormatterOrFallback } from 'utils/formatting'
import { ContactCompanyType } from '../../../../../models/contact_companies'
import { DataTableColumnSort } from '../../../../DataTable/DataTableSorting/DataTableSortingStore'
import { FbLink } from '../../../../FbUI/FbLink'
import {
  createTableColumnHelper,
  DataTypeColumn,
} from 'components/DataExpansionComponents/Common/commonColumnHelpers'
import { OverflownTextTooltip } from 'components/OverflownTextTooltip'
import {
  AllGooglePlacesAccessors,
  AllGooglePlacesChainProxies,
  GooglePlaceChainBase,
} from 'models/google_place_chain'

// New helper function to render tooltip for arrays of values
const renderOverflownTooltip = (values: string[] | undefined) => {
  if (!values) return '-'
  const sorted = values.sort()
  const display = sorted.slice(0, 25).join(', ')
  let total = sorted.length
  let totalDisplay = total.toString()
  if (total > 1000) {
    total = Math.floor(total / 1000)
    totalDisplay = `${total}k`
  }
  return (
    <OverflownTextTooltip
      tooltipText={`${display}, ... (${totalDisplay} more)`}
      maxLines={2}
    >
      {display}
    </OverflownTextTooltip>
  )
}

export const useGooglePlacesBaseTableCols = ({
  sorting,
  inCampaigns,
  accesor,
  tableColumns,
  linkPrefix,
}: {
  sorting?: DataTableColumnSort[]
  inCampaigns?: boolean
  accesor: AllGooglePlacesAccessors
  tableColumns: Record<keyof GooglePlaceChainBase, DataTypeColumn>
  linkPrefix: string
}) => {
  const { preferences } = usePreferences()
  const tooltips: Record<string, unknown> = preferences?.tooltips || {}
  const featureFlag = featureFlagService()
  const columnHelper = createColumnHelper<AllGooglePlacesChainProxies>()
  const googlePlaceColumnHelper = createTableColumnHelper({
    columns: tableColumns,
    tooltips,
  })

  const columns = useMemo(() => {
    let cols = [
      columnHelper.accessor(`${accesor}.name`, {
        ...googlePlaceColumnHelper.retrieveColumn('name', {
          meta: {
            isEditable: false,
            isFirstColumn: true,
          },
        }),
        header: () => null,
        size: 250,
        cell: (info) => {
          const name = info.getValue()
          let link = ''
          if (info.row.original?.[accesor]?.id) {
            link = `/${linkPrefix}/${
              preferences?.company_type_prefix_map[ContactCompanyType.Lodging]
            }${info.row.original?.[accesor]?.id}`
          } else {
            link = `/company/${
              preferences?.company_type_prefix_map[
                ContactCompanyType.UserGenerated
              ]
            }${info.row.original?.id}`
          }
          return (
            <FbLink to={link} target="_blank">
              {name}
            </FbLink>
          )
        },
      }),
      columnHelper.accessor(`${accesor}.doors_cnt`, {
        ...googlePlaceColumnHelper.retrieveColumn('doors_cnt'),
        size: 150,
        cell: (info) => {
          return integerFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor(`${accesor}.city_summary`, {
        ...googlePlaceColumnHelper.retrieveColumn('city_summary'),
        size: 250,
        cell: (info) => renderOverflownTooltip(info.getValue()),
      }),
      columnHelper.accessor(`${accesor}.state_summary`, {
        ...googlePlaceColumnHelper.retrieveColumn('state_summary'),
        size: 150,
        cell: (info) => renderOverflownTooltip(info.getValue()),
      }),
      columnHelper.accessor(`${accesor}.zipcode_summary`, {
        ...googlePlaceColumnHelper.retrieveColumn('zipcode_summary'),
        size: 200,
        cell: (info) => renderOverflownTooltip(info.getValue()),
      }),

      columnHelper.accessor(`${accesor}.reviews_cnt`, {
        ...googlePlaceColumnHelper.retrieveColumn('reviews_cnt'),
        size: 150,
        cell: (info) => {
          return integerFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor(`${accesor}.reviews_avg`, {
        ...googlePlaceColumnHelper.retrieveColumn('reviews_avg'),
        size: 150,
        cell: (info) => {
          return integerFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor(`${accesor}.domain`, {
        ...googlePlaceColumnHelper.retrieveColumn('domain'),
        size: 150,
        cell: (info) => {
          const value = info.getValue()
          if (!value) {
            return '-'
          }
          return (
            <Anchor href={'//' + value} target="_blank">
              {value}
            </Anchor>
          )
        },
      }),
      ...getCommonContactCompanyColumns(
        columnHelper,
        sorting,
        preferences,
        inCampaigns
      ),
    ]
    if (!featureFlag.enableCampaigns) {
      cols = cols.filter((col) => !col.id?.includes('campaigns'))
    }
    return cols
  }, [
    columnHelper,
    sorting,
    featureFlag,
    googlePlaceColumnHelper,
    preferences,
    inCampaigns,
  ])
  return columns
}
