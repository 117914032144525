import CommonPublicForm from './common-public-form'

export default function DataRequestSuccess() {
  return (
    <div className="py-20 bg-gray-100 h-screen">
      <CommonPublicForm
        mainTitle="Data Request Form"
        description="Thank you for your submission. We have received your data request and will process it accordingly."
      ></CommonPublicForm>
    </div>
  )
}
