import apiService from 'services/api'
import CommonPublicForm from './common-public-form'
import { BaseTextInput } from 'components/FormUtils/TextInput/text-input'
import FbButton from 'components/FbUI/FbButton'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import ReactSelectWrapper from 'components/FormUtils/ReactSelectWrapper'

export default function DataRequestForm() {
  const api = apiService()
  const { data } = api.useGetTextContent()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsLoading(true)
    const formData = new FormData(e.currentTarget)

    try {
      await api.dataRequestSubmission({
        first_name: formData.get('first_name') as string,
        last_name: formData.get('last_name') as string,
        email: formData.get('email') as string,
        data_request_type: formData.get('data_request_type') as string,
      })
      navigate('/data-request-success')
    } catch (error) {
      console.error('Error submitting form:', error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="py-20 bg-gray-100 h-full">
      <CommonPublicForm
        mainTitle="Data Request Form"
        formTitle="Data Request Form"
        description={data?.data_request_form}
      >
        <form onSubmit={handleSubmit}>
          <div className="flex gap-2">
            <BaseTextInput
              name="first_name"
              placeholder="First Name"
              className="w-full"
              required
            />
            <BaseTextInput
              name="last_name"
              placeholder="Last Name"
              className="w-full"
              required
            />
          </div>

          <div>
            <BaseTextInput
              name="email"
              type="email"
              placeholder="Email"
              className="w-full"
              required
            />
          </div>

          <div className="mt-3">
            <ReactSelectWrapper
              name="data_request_type"
              placeholder="Please select a request"
              className="w-full"
              options={[
                { label: 'Request Access', value: 'Request Access' },
                {
                  label: 'Request Data Deletion',
                  value: 'Request Data Deletion',
                },
              ]}
              required
            />
          </div>

          <div className="mt-3">
            <FbButton loading={isLoading} type="submit" className="w-full">
              Submit Request
            </FbButton>
          </div>
        </form>
      </CommonPublicForm>
    </div>
  )
}
