import { Form, InputGroup } from 'react-bootstrap'
import { Control, FieldValues, Path, useController } from 'react-hook-form'
import { ErrorLabel } from '../ErrorLabel'
import React from 'react'
import { BsX } from 'react-icons/bs'

interface CommonTextInputProps {
  label?: string
  placeholder?: string
  type?: string
  maxLength?: number
  optional?: boolean
  secondaryLabel?: string
  prefix?: string
  suffix?: string
  style?: React.CSSProperties
  sublabel?: string
  noMargin?: boolean
  disabled?: boolean
  className?: string
  helperText?: string
  onClear?: () => void
  required?: boolean
}

interface BaseTextInputProps extends CommonTextInputProps {
  name: string
  value?: string | number
  onChange?: (value: string | number) => void
  error?: string
}

export interface TextInputProps<T extends FieldValues>
  extends CommonTextInputProps {
  control: Control<T>
  name: Path<T>
}

export function BaseTextInput(props: BaseTextInputProps) {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!props.onChange) return
    if (props.type === 'number') {
      if (isNaN(e.target.valueAsNumber)) {
        props.onChange('')
      } else {
        props.onChange(e.target.valueAsNumber)
      }
    } else {
      props.onChange(e.target.value)
    }
  }

  return (
    <div
      className={`flex flex-col w-full ${props.noMargin ? 'mt-0' : 'mt-[10px]'}`}
    >
      <div className={props.noMargin ? 'mt-0' : 'mb-1'}>
        {props.label && (
          <label
            htmlFor={props.name}
            className="text-base font-medium text-gray-800 select-none"
          >
            {props.label}
            {props.optional && (
              <span className="italic text-sm font-normal"> - optional</span>
            )}
            {props.secondaryLabel && (
              <span className="italic text-sm font-normal">
                {props.secondaryLabel}
              </span>
            )}
          </label>
        )}
        {props.sublabel && (
          <label className="text-gray-600 text-sm font-normal leading-5">
            {props.sublabel}
          </label>
        )}
      </div>
      <div className="relative">
        {props.onClear && !!props.value && (
          <button
            className="z-10 absolute right-10 top-1 text-sm text-gray-600 hover:text-gray-800 focus:outline-none"
            onClick={() => props.onClear?.()}
          >
            <BsX size={24} />
          </button>
        )}

        <InputGroup>
          {props.prefix && <InputGroup.Text>{props.prefix}</InputGroup.Text>}
          <Form.Control
            id={props.name}
            onChange={props.onChange ? handleOnChange : undefined}
            type={props.type}
            value={props.value ?? undefined}
            placeholder={props.placeholder}
            maxLength={props.maxLength}
            style={props.style}
            disabled={props.disabled}
            className={`${props.className} ${props.error ? 'input-error' : ''}`}
            name={props.name}
            required={props.required}
          />
          {props.suffix && <InputGroup.Text>{props.suffix}</InputGroup.Text>}
        </InputGroup>
      </div>
      {props.helperText && (
        <span className="mt-1 text-gray-600 text-sm font-normal leading-5">
          {props.helperText}
        </span>
      )}
      {props.error && <ErrorLabel message={props.error} />}
    </div>
  )
}

export function TextInput<T extends FieldValues>(props: TextInputProps<T>) {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ name: props.name, control: props.control })

  return (
    <BaseTextInput
      {...props}
      value={value}
      onChange={onChange}
      error={error?.message}
    />
  )
}
