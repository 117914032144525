import { Modal } from 'components/UI/Modal/Modal'
import { useRef, useState } from 'react'
import QRCode from 'react-qr-code'
import { toast } from 'react-toastify'

interface AttributionCode {
  id: number
  code: string
  name: string
  views: number
  enrollments: number
  visits: number
  is_default?: boolean
}

function QRCodeModal({
  baseUrl,
  offerName,
  attributionCodes = [],
  isOpen,
  onClose,
}: {
  baseUrl: string
  offerName: string
  attributionCodes?: AttributionCode[]
  isOpen: boolean
  onClose: () => void
}) {
  const qrRef = useRef<HTMLDivElement>(null)
  const [selectedCode, setSelectedCode] = useState<AttributionCode | null>(
    attributionCodes.find((tag) => tag.is_default) ||
      attributionCodes[0] ||
      null
  )

  // Get the complete URL with attribution code if available
  const getUrl = () => {
    if (!selectedCode) return baseUrl

    // Don't add the ac parameter if this is the default code
    if (selectedCode.is_default) return baseUrl

    return `${baseUrl}?ac=${selectedCode.code}`
  }

  const url = getUrl()

  // Function to download QR code as PNG
  const downloadQRCode = () => {
    if (!qrRef.current) return

    // Get the SVG element
    const svg = qrRef.current.querySelector('svg')
    if (!svg) return

    // Create a canvas element
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    if (!ctx) return

    // Set canvas dimensions
    const scaleFactor = 4 // Make the downloaded image larger and clearer
    canvas.width = svg.clientWidth * scaleFactor
    canvas.height = svg.clientHeight * scaleFactor

    // Create an image from the SVG
    const svgData = new XMLSerializer().serializeToString(svg)
    const img = new Image()

    img.onload = () => {
      // Fill with white background
      ctx.fillStyle = 'white'
      ctx.fillRect(0, 0, canvas.width, canvas.height)

      // Draw the image
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height)

      // Create download link
      const downloadLink = document.createElement('a')
      let fileName = `${offerName.replace(/\s+/g, '-').toLowerCase()}`
      if (selectedCode && !selectedCode.is_default) {
        fileName += `-${selectedCode.name.replace(/\s+/g, '-').toLowerCase()}`
      }
      fileName += '-qrcode.png'

      downloadLink.download = fileName
      downloadLink.href = canvas.toDataURL('image/png')
      downloadLink.click()

      toast.success('QR Code downloaded successfully!')
    }

    img.src =
      'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgData)))
  }

  return (
    <Modal
      open={isOpen}
      onOpenChange={(open: boolean) => !open && onClose()}
      title="QR Code Generator"
      acceptButtonText="Download QR Code"
      onAccept={downloadQRCode}
    >
      <div className="p-6">
        {/* Instructions */}
        <div className="mb-6">
          <h3 className="text-lg font-medium text-gray-900 mb-2">
            {offerName}
          </h3>
          <p className="text-sm text-gray-600">
            Easily share this offer by using a QR code. Customers can scan it
            with their phone to access the offer.
          </p>
        </div>

        {/* Attribution Code Selector */}
        {attributionCodes.length > 0 && (
          <div className="mb-6">
            <label
              htmlFor="attribution-select"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Attribution Source
            </label>
            <select
              id="attribution-select"
              className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-fb-dark-green focus:border-fb-dark-green"
              value={selectedCode?.id || ''}
              onChange={(e) => {
                const tagId = parseInt(e.target.value)
                const tag = attributionCodes.find((t) => t.id === tagId) || null
                setSelectedCode(tag)
              }}
            >
              {attributionCodes.map((tag) => (
                <option key={tag.id} value={tag.id}>
                  {tag.name} {tag.is_default ? '(Default)' : ''}
                </option>
              ))}
            </select>
            <p className="mt-2 text-xs text-gray-500">
              {selectedCode?.is_default
                ? "Using the default attribution code doesn't add parameters to the URL."
                : 'This attribution code will be tracked when customers use this link.'}
            </p>
          </div>
        )}

        {/* QR Code Display - Full Width */}
        <div
          ref={qrRef}
          className="bg-white p-6 rounded-lg border border-gray-200 shadow-sm flex flex-col items-center justify-center"
        >
          <QRCode
            value={url}
            size={250}
            style={{ height: 'auto', width: '100%', maxWidth: '250px' }}
          />
          <div className="mt-4 text-sm text-gray-500 break-all text-center">
            {url}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default QRCodeModal
