import { CellContext, ColumnDef } from '@tanstack/react-table'
import { useState, useMemo, useEffect } from 'react'
import { toast } from 'react-toastify'
import { BiPlus, BiCopy } from 'react-icons/bi'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { MdCheck } from 'react-icons/md'

// UI Components
import { JobActionButtons } from '../../components/Buttons/ActionButtons'
import {
  DataTable,
  DataTableProvider,
  useDataTableContext,
} from '../../components/DataTable'
import { DataTableContainer } from '../../components/DataTable/UI'
import { IColumnVisibility } from '../../components/DataTable/types'
import { ColumnSelectorRecipient } from '../../components/Modals/ColumnModal/ColumnModal'
import { Modal } from '../../components/UI/Modal/Modal'
import FbButton from '../../components/FbUI/FbButton'
import * as S from '../../components/Tables/CommonTable.styles'
import { TableSearch } from '../../components/Filters/TableSearch'
import { FilterChips } from '../../components/Filters/components/FilterChips/FilterChips'
import {
  filterStoreRepo,
  useFilterParams,
} from '../../components/Filters/FilterStore'
import { FilterIdentifier } from '../../models/saved_view'
import { dataTableSortingStoreRepo } from '../../components/DataTable/DataTableSorting/DataTableSortingStore'
import { ColorPicker, ColorpickerColor } from '../../components/ColorPicker'
import { TAG_COLORS } from '../../utils/constants'
import { TextInput } from '../../components/FormUtils/TextInput'
import { TextareaInput } from '../../components/FormUtils/TextareaInput'

// Services and stores
import apiService from '../../services/api'
import { ColumnsStoreProvider } from '../../stores/ColumnsStore/ColumnsStoreProvider'

// Types and utilities
import { AttributionCode, AttributionCodeCreateRequest } from './tradespend_api'
import { useAttributionCodesTableColumns } from './useAttributionCodesTableColumns'
import AttributionCodeDetailsModal from './components/AttributionCodeDetailsModal'
import { AttributionCodesFilterset } from './AttributionCodesFilterset'

// Table key for data table
const TABLE_KEY = 'rebate-attribution-codes-table'
const PAGE_SIZE = 20 // Set appropriate page size

// Create Attribution Tag Modal component
function CreateAttributionCodeModal({
  open,
  onClose,
  offerId,
  onSuccess,
}: {
  open: boolean
  onClose: () => void
  offerId: number
  onSuccess: () => void
}) {
  const api = apiService()
  const [createdTag, setCreatedTag] = useState<AttributionCode | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  type AttributionCodeForm = {
    name: string
    description: string
    color: ColorpickerColor
  }

  const defaultValues: AttributionCodeForm = {
    name: '',
    description: '',
    color: TAG_COLORS[0],
  }

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm<AttributionCodeForm>({
    defaultValues,
    mode: 'onChange',
    resolver: zodResolver(
      z.object({
        name: z.string().min(1, 'Name is required'),
        description: z.string().optional(),
        color: z.any(),
      })
    ),
  })

  useEffect(() => {
    if (!open) {
      reset(defaultValues)
      setCreatedTag(null)
      setIsSubmitting(false)
    }
  }, [open, reset])

  const submitForm = async (values: AttributionCodeForm) => {
    if (isSubmitting) return

    setIsSubmitting(true)
    try {
      const createData: AttributionCodeCreateRequest = {
        offer_id: offerId,
        name: values.name,
        description: values.description || undefined,
        color: values.color.background,
      }

      const response = await api.createAttributionCode(createData)

      // Store the created code to show the code
      setCreatedTag(response)
    } catch (error) {
      console.error('Error creating attribution code:', error)
      toast.error('Failed to create attribution code')
      onClose()
    } finally {
      setIsSubmitting(false)
    }
  }

  // Generate the shareable URL if we have a created code
  const shareableUrl = createdTag
    ? `${import.meta.env.VITE_TRADESPEND_APP_HOST || 'http://localhost:5174'}/offer/${createdTag.offer_code}?ac=${createdTag.code}`
    : ''

  // Copy the shareable URL to clipboard
  const [copied, setCopied] = useState(false)
  const copyToClipboard = () => {
    if (!shareableUrl) return

    navigator.clipboard
      .writeText(shareableUrl)
      .then(() => {
        setCopied(true)
        toast.success('Link copied to clipboard')

        // Reset copied state after 2 seconds
        setTimeout(() => {
          setCopied(false)
        }, 2000)
      })
      .catch((err) => {
        console.error('Failed to copy: ', err)
        toast.error('Failed to copy link')
      })
  }

  return (
    <Modal
      open={open}
      onOpenChange={onClose}
      title="Create Attribution Code"
      size="md"
      loading={isSubmitting}
      blockAccept={!createdTag && !isValid}
      onAccept={
        createdTag
          ? () => {
              onSuccess() // Refetch data after user views the code
              onClose()
            }
          : handleSubmit(submitForm)
      }
      acceptButtonText={createdTag ? 'Done' : 'Create Code'}
    >
      {!createdTag ? (
        <div className="p-4 space-y-4">
          <TextInput
            control={control}
            name="name"
            label="Name"
            placeholder="e.g. Email Newsletter"
            optional={false}
          />

          <TextareaInput
            control={control}
            name="description"
            label="Description"
            placeholder="Optional description..."
            optional={true}
          />

          <Controller
            name="color"
            control={control}
            render={({ field }) => (
              <ColorPicker {...field} colors={TAG_COLORS} label="Color" />
            )}
          />
        </div>
      ) : (
        <div className="p-4 space-y-4">
          <div className="flex items-center space-x-2">
            <div
              className="w-4 h-4 rounded-full"
              style={{ backgroundColor: createdTag.color || '#ccc' }}
            />
            <h3 className="text-lg font-medium">{createdTag.name}</h3>
            <span className="bg-green-100 text-green-800 text-xs px-2 py-1 rounded">
              Created Successfully
            </span>
          </div>

          {/* Attribution Code */}
          <div className="mt-4">
            <h4 className="text-sm font-medium text-gray-700 mb-2">
              Attribution Code
            </h4>
            <div className="flex items-center">
              <code className="bg-gray-100 px-3 py-2 rounded font-mono text-sm flex-1">
                {createdTag.code}
              </code>
            </div>
          </div>

          {/* Shareable Link */}
          <div className="mt-4">
            <h4 className="text-sm font-medium text-gray-700 mb-2">
              Shareable Link
            </h4>
            <div className="flex items-center">
              <input
                type="text"
                readOnly
                value={shareableUrl}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              />
              <FbButton
                onClick={copyToClipboard}
                className="ml-2 px-3"
                title="Copy shareable link"
              >
                {copied ? <MdCheck size={18} /> : <BiCopy size={18} />}
              </FbButton>
            </div>
            <p className="text-xs text-gray-500 mt-1">
              Share this link with users to track this attribution source
            </p>
          </div>

          {createdTag.description && (
            <div className="mt-4">
              <h4 className="text-sm font-medium text-gray-700 mb-2">
                Description
              </h4>
              <p className="text-sm text-gray-600">{createdTag.description}</p>
            </div>
          )}

          <div className="bg-blue-50 border border-blue-200 text-blue-700 p-4 rounded-md mt-6">
            <p className="text-sm">
              Your attribution code has been created successfully. Use the link
              above to share this offer with the new attribution code.
            </p>
          </div>
        </div>
      )}
    </Modal>
  )
}

// Main component
function RebateOfferAttributionTabComponent({
  offerId,
  tableKey,
}: {
  offerId: number
  tableKey: string
}) {
  const api = apiService()
  const [selectedAttributionCodeId, setSelectedAttributionCodeId] = useState<
    number | null
  >(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  // Filter store setup
  const IDENTIFIER = 'ATTRIBUTION_CODES_TABLE'
  const attributionTagsFilterStore = filterStoreRepo.getStore(
    IDENTIFIER as FilterIdentifier
  )
  const { page, setPage } = attributionTagsFilterStore()

  const {
    methods: { setTotalRowsInBackend },
  } = useDataTableContext()

  const { sorting } = dataTableSortingStoreRepo.getStore(tableKey)()

  // Filter params
  const filtersAsParams = useFilterParams(attributionTagsFilterStore)

  const sortParams = useMemo(() => {
    const params: Record<string, string> = {}
    if (sorting?.length) {
      params['sort'] = sorting[0].desc ? '-' + sorting[0]?.id : sorting[0]?.id
    }
    return params
  }, [sorting])

  // Create compound params with filters and sorting
  const requestParams = useMemo(
    () => ({
      ...filtersAsParams,
      ...sortParams,
      offer_id: offerId,
    }),
    [filtersAsParams, sortParams, offerId]
  )

  // Fetch attribution codes for the offer
  const {
    data: attributionCodesData,
    isFetching,
    refetch,
  } = api.useGetAttributionCodes(offerId, requestParams)

  // Update total rows count when data changes
  useMemo(() => {
    if (attributionCodesData?.count) {
      setTotalRowsInBackend(attributionCodesData.count)
    }
  }, [attributionCodesData, setTotalRowsInBackend])

  // Get base columns from the hook
  const baseColumns = useAttributionCodesTableColumns()

  // Add actions column to the base columns
  const columns: ColumnDef<AttributionCode, any>[] = useMemo(() => {
    return [
      ...baseColumns,
      {
        id: 'actions',
        meta: {
          isLastColumn: true,
          headerDisableOrdering: true,
        },
        header: () => <div className="ml-[-8px]">Actions</div>,
        size: 80,
        cell: (info: CellContext<AttributionCode, unknown>) => {
          return (
            <JobActionButtons
              className={'justify-center pr-0'}
              onView={() => setSelectedAttributionCodeId(info.row.original.id)}
            />
          )
        },
      },
    ]
  }, [baseColumns])

  // Find the selected attribution code
  const selectedAttributionCode = useMemo(() => {
    if (!selectedAttributionCodeId || !attributionCodesData?.results) {
      return null
    }
    return attributionCodesData.results.find(
      (code: AttributionCode) => code.id === selectedAttributionCodeId
    )
  }, [selectedAttributionCodeId, attributionCodesData])

  const sortableFields = [
    'name',
    'view_count',
    'total_enrollments',
    'total_claims',
    'pending_claims',
    'invalid_claims',
    'paid_claims',
    'unpaid_valid_claims',
    'total_validated_units',
    'total_validated_payout',
    'created',
    'modified',
    'url',
  ]

  return (
    <>
      <DataTableContainer>
        <DataTable
          tableKey={tableKey}
          loading={isFetching}
          stickyLastColumn={true}
          data={attributionCodesData?.results ?? []}
          columns={columns}
          virtualizeRows={false}
          sortableFields={sortableFields}
          defaultSort={[{ id: 'created', desc: true }]}
          emptyTableText={{
            title: 'No attribution codes found',
            subtitle: 'Create an attribution code to get started',
          }}
          isPaginationEnabled={
            !!attributionCodesData?.count &&
            attributionCodesData.count > PAGE_SIZE
          }
          paginationOptions={{
            totalRows: attributionCodesData?.count ?? 0,
            pageSize: PAGE_SIZE,
            setPage: setPage,
            page: page,
            isPaginationLoading: isFetching,
          }}
          tableHeader={
            <S.SearchContainer>
              <S.SearchContainerFilters>
                <S.TableSearchContainer>
                  <TableSearch
                    filterStore={attributionTagsFilterStore}
                    searchPlaceholder="Search attribution codes"
                  />
                </S.TableSearchContainer>
                <S.TableButtonsContainer>
                  <AttributionCodesFilterset
                    filterIdentifier={IDENTIFIER as FilterIdentifier}
                  />
                  <ColumnSelectorRecipient tableKey={tableKey} />
                  <FbButton
                    onClick={() => setIsModalOpen(true)}
                    className="flex items-center gap-2"
                    data-testid="create-attribution-code-button"
                  >
                    <BiPlus size={18} />
                    <span>Create Attribution Code</span>
                  </FbButton>
                </S.TableButtonsContainer>
              </S.SearchContainerFilters>
              <FilterChips
                identifier={IDENTIFIER as FilterIdentifier}
                store={attributionTagsFilterStore}
                clearAllButton
                showActive
              />
            </S.SearchContainer>
          }
        />
      </DataTableContainer>

      {selectedAttributionCode && (
        <AttributionCodeDetailsModal
          open={true}
          onClose={() => setSelectedAttributionCodeId(null)}
          code={selectedAttributionCode}
        />
      )}

      <CreateAttributionCodeModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        offerId={offerId}
        onSuccess={() => {
          refetch()
        }}
      />
    </>
  )
}

// Export wrapped component with providers
export function RebateOfferAttributionTab({
  offerId,
  defaultColumnVisibility,
}: {
  offerId: number
  defaultColumnVisibility?: IColumnVisibility<any>
}) {
  return (
    <ColumnsStoreProvider
      tableKey={TABLE_KEY}
      defaultColumnVisibility={defaultColumnVisibility}
      preferredGroupOrder={['Tag Information', 'Usage Information', 'Metadata']}
    >
      <DataTableProvider tableKey={TABLE_KEY}>
        <RebateOfferAttributionTabComponent
          offerId={offerId}
          tableKey={TABLE_KEY}
        />
      </DataTableProvider>
    </ColumnsStoreProvider>
  )
}
