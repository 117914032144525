import { useMutation, useQuery } from '@tanstack/react-query'
import to from 'await-to-js'
import axios, { AxiosError } from 'axios'
import { CHAIN_SUMMARY_KEY, DOOR_SUMMARY_KEY } from 'constants/tableQueryKeys'
import { ActivityListResponse } from 'models/activity'
import { ActivityListResponseV2 } from 'models/activityV2'
import { AsyncJobListResponse } from 'models/asyncJobs'
import {
  Category,
  CompanyCategorySlug,
  CompanyDistributor,
  CompanyDistributorCreate,
  CompanyTypeSlug,
  ContactCompanySearchResult,
} from 'models/companies'
import {
  ContactCreateRequest,
  ContactListResponse,
  ContactsImportResponse,
} from 'models/contacts'
import { ProductPrediction } from 'models/deals'
import { DealsPipeline } from 'models/deals_pipeline'
import { HospitalChainProxy } from 'models/hospital'
import { NursingHomeChainProxy } from 'models/nursinghome'
import { Menu } from 'models/menus'
import { Notification } from 'models/notifications'
import { Preferences } from 'models/preferences'
import {
  DoorProxyDetail,
  DoorProxyList,
  ReviewsPerScore,
} from 'models/restaurant'
import { FilterIdentifier, SavedView, SavedViewCreate } from 'models/saved_view'
import { SuggestionCreate } from 'models/suggestion'
import { ChainsSummary, PlacesSummary } from 'models/summaries'
import { UniversityChainProxy } from 'models/universities'
import { User } from 'models/user'
import { useMemo } from 'react'
import {
  getCampaigns,
  useCreateCampaign,
  useDeleteCampaign,
  useEditCampaign,
  useGetCampaignCompanies,
  useGetCampaigns,
  useGetCampaignsOptions,
} from '../features/campaigns/campaigns_api'
import {
  createRebateOffer,
  getRebateSuggestions,
  useGetRebateOffer,
  useGetRebateClaims,
  useGetRebateEnrollments,
  useGetRebateEnrollmentDetails,
  useManufacturerInvoices,
  getRebateDashboardStats,
  useGetRebateDashboardStats,
  getRebateOffers,
  useGetRebateOfferOptions,
  useGetCurrentManufacturerInvoice,
  getAttributionCodes,
  useGetAttributionCodes,
  createAttributionCode,
  updateAttributionCode,
  deleteAttributionCode,
} from '../features/tradespend/tradespend_api'
import { SalesPipeline } from '../models/SalesPipeline'
import { ChainListResponse } from '../models/chains'
import {
  IContactCompany,
  IContactCompanyCreateRequest,
  IContactCompanyUpdateRequest,
  IDoorProxy,
  IDoorProxyCreateRequest,
  IDoorProxyEditRequest,
  IDoorProxyListResponse,
  INote,
} from '../models/contact_companies'
import { Deal, DealEdit, DealHistory } from '../models/deals'
import {
  PaginatedResponse,
  StandardSummaryResponse,
} from '../models/paginated-response'
import { Product } from '../models/product'
import { SalesStage } from '../models/sales_stages'
import { Tag } from '../models/tags'
import getStageColor from '../utils/getStageColor'
import { clientInstance } from '../utils/http-client'
import { bulkEditSingleParams } from './apiTypes'
import {
  createColumnPreset,
  deleteColumnPreset,
  useGetAllColumnPresets,
  useGetColumnPresetOptions,
} from './column_presets_api'
import {
  createContactRequestJob,
  fetchContactRequestJobDetails,
  fetchContactRequestJobs,
  fetchRequestedContacts,
  useRequestContactsFromCampaign,
} from './contactRequestApi'
import {
  exportK12Districts,
  getK12DistrictsList,
  useGetK12District,
  useK12CountiesOptions,
  getK12DistrictsSummary,
} from './k12api'
import { queryClient } from './queryClient'
import { notifyError } from './toast'
import { ContactCampaign } from 'components/ImportContactFlow/ImportContactModal/ImportContactContext'
import {
  deleteEmailIntegration,
  getEmailIntegrationAuthUrl,
  getEmailIntegrationList,
  getEmailMessageById,
  getEmailMessageList,
  sendEmailMessage,
  syncEmailIntegration,
  updateEmailIntegration,
  useGetEmailIntegrationOptions,
} from './emailIntegrationApi'
import {
  commonCreateDeals,
  commonManageDistributors,
  commonManageTags,
  useAssignToCampaignMutation,
  useUnassignToCampaignMutation,
} from './common_table_actions'
import { baseAPICall } from './baseAPICall'
import { LodgingChainProxy } from 'models/google_place_chain'
import { LodgingDoorProxy } from 'models/google_place_door'

import { MISC_SETTINGS_TYPES } from 'models/misc_settings'

type tagParent = 'products' | 'contacts'

export default function apiService() {
  async function getProducts() {
    return baseAPICall<Product[]>(() => clientInstance.get('products/'))
  }

  const useGetProducts = (disabled = false) =>
    useQuery({
      queryKey: ['products'],
      queryFn: getProducts,
      refetchOnMount: false,
      enabled: !disabled,
    })

  async function getProductsOptions(withNoneOption?: boolean) {
    return baseAPICall<
      {
        label: string
        value: number
        original: Product
      }[]
    >(() =>
      clientInstance.get('products/', {
        transformResponse: (data) => {
          const products = JSON.parse(data)
          const opts = products.map((product: Product) => ({
            label: product.name,
            value: product.id,
            original: product,
          }))

          if (withNoneOption) {
            opts.unshift({ label: 'No Product', value: -1 })
          }

          return opts
        },
      })
    )
  }

  const useGetProductsOptions = (withNoneOption?: boolean) =>
    useQuery({
      queryKey: ['productsOptions', withNoneOption ? 'with-none' : ''],
      queryFn: () => getProductsOptions(withNoneOption),
    })

  async function postProduct(values: Omit<Product, 'id'>) {
    delete values.company

    return baseAPICall<Product>(
      () => clientInstance.post('products/', values),
      { successMessage: 'Product created successfully' }
    )
  }

  async function putProduct(id: number, values: Omit<Product, 'id'>) {
    delete values.company

    return baseAPICall<Product>(
      () => clientInstance.put(`products/${id}/`, values),
      { successMessage: 'Product updated successfully' }
    )
  }

  async function deleteProduct(id: number) {
    return baseAPICall(() => clientInstance.delete(`products/${id}/`), {
      successMessage: 'Product deleted successfully',
    })
  }

  async function getRestaurantsV2(params = {}, signal?: AbortSignal) {
    return baseAPICall<PaginatedResponse<DoorProxyList>>(() =>
      clientInstance.get('restaurants/places/', {
        signal,
        params,
      })
    )
  }

  async function getPlacesSummaryV2(params = {}, signal?: AbortSignal) {
    return baseAPICall<PlacesSummary>(() =>
      clientInstance.get('restaurants/places/summary/', {
        signal,
        params,
        transformResponse: (data) => {
          return JSON.parse(data)
        },
      })
    )
  }

  const useGetPlacesSummaryV2 = (
    params: Record<string, any> = {},
    signal?: AbortSignal,
    enabled: boolean = true
  ) =>
    useQuery({
      queryKey: [DOOR_SUMMARY_KEY, params],
      queryFn: () => {
        return getPlacesSummaryV2({ ...params }, signal)
      },
      refetchOnMount: false,
      enabled: enabled,
    })

  async function getChainsSummaryV2(params = {}, signal?: AbortSignal) {
    return baseAPICall<ChainsSummary>(() =>
      clientInstance.get('restaurants/chains/summary/', {
        signal,
        params,
        transformResponse: (data) => {
          return JSON.parse(data)
        },
      })
    )
  }

  const useGetChainsSummaryV2 = (params = {}, signal?: AbortSignal) =>
    useQuery({
      queryKey: [CHAIN_SUMMARY_KEY, params],
      queryFn: () => {
        return getChainsSummaryV2({ ...params }, signal)
      },
    })

  async function getPlaceV2(placeId?: string): Promise<DoorProxyDetail> {
    if (!placeId) {
      notifyError('no place provided')
      throw new Error('no place provided')
    }
    const url = `restaurants/places/${placeId}/`
    const [err, res] = await to(clientInstance.get(url))

    if (err) {
      notifyError(err.message)
      throw err
    }

    return res.data
  }

  const useGetPlaceV2 = (placeId?: string) =>
    useQuery<DoorProxyDetail, AxiosError>({
      queryKey: ['place', placeId],
      queryFn: () => getPlaceV2(placeId),

      enabled: !!placeId,
      retry: (failureCount, error: Error | AxiosError) => {
        return axios.isAxiosError(error) && error?.response?.status === 404
          ? false
          : failureCount < 3
      },
    })

  type ChainDetail = {
    distributors: any[]

    taglist: any[]

    instagram: {
      followers: number
      following: number
      posts: number
      percentile: number
      text_percentile: string
      url: string
    }

    total_ltv_sum: number
    revenue_ltv_sum: number
    brand_ltv_sum: number

    domain: string

    logo: any
    pop_density: number
    median_hhi: number
    reviews_per_score: ReviewsPerScore
    rating: number
    reviews_count: number
    cuisine_50: string
    expense_category: string
    count: number
    website: string
    description: string
    chain: string
    id: number
    contact_company_id?: number
  }

  async function getChain(chainId?: number): Promise<ChainDetail> {
    if (!chainId) {
      notifyError('no chain provided')
      throw new Error('no chain provided')
    }

    const url = `restaurants/chains/${chainId}/`
    const [err, res] = await to(clientInstance.get(url))

    if (err) {
      notifyError(err.message)
      throw err
    }

    return res.data
  }

  async function getUniversity(
    universityId?: number
  ): Promise<UniversityChainProxy> {
    if (!universityId) {
      notifyError('no university provided')
      throw new Error('no university provided')
    }

    const url = `universities/${universityId}/`
    const [err, res] = await to(clientInstance.get(url))

    if (err) {
      notifyError(err.message)
      throw err
    }

    return res.data
  }

  async function getUniversitySummary(params = {}) {
    return baseAPICall<StandardSummaryResponse>(() =>
      clientInstance.get(`/universities/summary/`, {
        params,
      })
    )
  }

  async function getHospital(hospitalId?: number): Promise<HospitalChainProxy> {
    if (!hospitalId) {
      notifyError('no hospital provided')
      throw new Error('no hospital provided')
    }
    const url = `hospital/${hospitalId}/`
    const [err, res] = await to(clientInstance.get(url))

    if (err) {
      notifyError(err.message)
      throw err
    }

    return res.data
  }

  async function getUniversitiesList(params = {}) {
    return baseAPICall<PaginatedResponse<UniversityChainProxy>>(() =>
      clientInstance.get(`/universities/`, {
        params,
      })
    )
  }

  async function getHospitalsList(params = {}) {
    return baseAPICall<PaginatedResponse<HospitalChainProxy>>(() =>
      clientInstance.get(`/hospital/`, {
        params,
      })
    )
  }

  async function getHospitalsSummary(params = {}) {
    return baseAPICall<StandardSummaryResponse>(() =>
      clientInstance.get(`/hospital/summary/`, {
        params,
      })
    )
  }

  async function getNursingHome(
    nursingHomeId?: number
  ): Promise<NursingHomeChainProxy> {
    if (!nursingHomeId) {
      notifyError('no nursing home provided')
      throw new Error('no nursing home provided')
    }
    const url = `nursing_home/${nursingHomeId}/`
    const [err, res] = await to(clientInstance.get(url))

    if (err) {
      notifyError(err.message)
      throw err
    }

    return res.data
  }

  async function getNursingHomesList(params = {}) {
    return baseAPICall<PaginatedResponse<NursingHomeChainProxy>>(() =>
      clientInstance.get(`/nursing_home/`, {
        params,
      })
    )
  }

  async function getNursingHomesSummary(params = {}) {
    return baseAPICall<StandardSummaryResponse>(() =>
      clientInstance.get(`/nursing_home/summary/`, {
        params,
      })
    )
  }

  async function getLodgingList(params = {}) {
    return baseAPICall<PaginatedResponse<LodgingChainProxy>>(() =>
      clientInstance.get(`/places/lodging/`, {
        params,
      })
    )
  }

  async function getLodgingSummary(params = {}) {
    return baseAPICall<StandardSummaryResponse>(() =>
      clientInstance.get(`/places/lodging/summary/`, {
        params,
      })
    )
  }

  async function getLodgingDoorList(params = {}) {
    return baseAPICall<PaginatedResponse<LodgingDoorProxy>>(() =>
      clientInstance.get(`/places/lodging-doors/`, {
        params,
      })
    )
  }

  async function getLodgingDoorSummary(params = {}) {
    return baseAPICall<StandardSummaryResponse>(() =>
      clientInstance.get(`/places/lodging-doors/summary/`, {
        params,
      })
    )
  }

  function exportNursingHomes(
    params = {},
    selectedColumns: string[],
    exportType: 'csv' | 'xlsx',
    ids: number[] | undefined,
    excludeIds: number[] | undefined,
    signal?: AbortSignal
  ) {
    return baseAPICall(() =>
      clientInstance.post(
        'nursing_home/export/',
        {
          selected_columns: selectedColumns,
          export_type: exportType,
          include_ids: ids,
          exclude_ids: excludeIds,
        },
        {
          responseType: 'blob',
          signal,
          params,
        }
      )
    )
  }

  function exportLodging(
    params = {},
    selectedColumns: string[],
    exportType: 'csv' | 'xlsx',
    ids: number[] | undefined,
    excludeIds: number[] | undefined,
    signal?: AbortSignal
  ) {
    return baseAPICall(() =>
      clientInstance.post(
        'places/lodging/export/',
        {
          selected_columns: selectedColumns,
          export_type: exportType,
          include_ids: ids,
          exclude_ids: excludeIds,
        },
        {
          responseType: 'blob',
          signal,
          params,
        }
      )
    )
  }

  function exportLodgingDoors(
    params = {},
    selectedColumns: string[],
    exportType: 'csv' | 'xlsx',
    ids: number[] | undefined,
    excludeIds: number[] | undefined,
    signal?: AbortSignal
  ) {
    return baseAPICall(() =>
      clientInstance.post(
        'places/lodging-doors/export/',
        {
          selected_columns: selectedColumns,
          export_type: exportType,
          include_ids: ids,
          exclude_ids: excludeIds,
        },
        {
          responseType: 'blob',
          signal,
          params,
        }
      )
    )
  }

  const useGetChain = (chainId?: number) =>
    useQuery<ChainDetail, AxiosError>({
      queryKey: ['chain', chainId],
      queryFn: () => getChain(chainId),
      enabled: !!chainId, // Only fetch when chainId is truthy
      retry: (failureCount, error: Error | AxiosError) => {
        // By defualt, useQuery performs 3 retries on network errors
        // No retry on 404 error; continue on other errors up to 3 times
        return axios.isAxiosError(error) && error?.response?.status === 404
          ? false
          : failureCount < 3
      },
    })

  async function getUsers() {
    return baseAPICall<User[]>(() => clientInstance.get('users/'))
  }

  const useGetUniversity = (universityId?: number) =>
    useQuery({
      queryKey: ['university', universityId],
      queryFn: () => getUniversity(universityId),
      enabled: !!universityId,
      retry: (failureCount, error: Error | AxiosError) => {
        // By defualt, useQuery performs 3 retries on network errors
        // No retry on 404 error; continue on other errors up to 3 times
        return axios.isAxiosError(error) && error?.response?.status === 404
          ? false
          : failureCount < 3
      },
    })

  const useGetHospital = (hospitalId?: number) =>
    useQuery({
      queryKey: ['hospital', hospitalId],
      queryFn: () => getHospital(hospitalId),
      enabled: !!hospitalId,
      retry: (failureCount, error: Error | AxiosError) => {
        // By default, useQuery performs 3 retries on network errors
        // No retry on 404 error; continue on other errors up to 3 times
        return axios.isAxiosError(error) && error?.response?.status === 404
          ? false
          : failureCount < 3
      },
    })

  async function getLodging(lodging?: number): Promise<LodgingChainProxy> {
    const url = `places/lodging/${lodging}/`
    const [err, res] = await to(clientInstance.get(url))

    if (err) {
      notifyError(err.message)
      throw err
    }

    return res.data
  }

  const useGetLodging = (lodgingId?: number) =>
    useQuery({
      queryKey: ['lodging', lodgingId],
      queryFn: () => getLodging(lodgingId),
      enabled: !!lodgingId,
      retry: (failureCount, error: Error | AxiosError) => {
        // By default, useQuery performs 3 retries on network errors
        // No retry on 404 error; continue on other errors up to 3 times
        return axios.isAxiosError(error) && error?.response?.status === 404
          ? false
          : failureCount < 3
      },
    })

  async function getLodgingDoor(lodging?: number): Promise<LodgingDoorProxy> {
    const url = `places/lodging-doors/${lodging}/`
    const [err, res] = await to(clientInstance.get(url))

    if (err) {
      notifyError(err.message)
      throw err
    }

    return res.data
  }

  const useGetLodgingDoor = (lodgingDoorId?: number) =>
    useQuery({
      queryKey: ['lodging', lodgingDoorId],
      queryFn: () => getLodgingDoor(lodgingDoorId),
      enabled: !!lodgingDoorId,
      retry: (failureCount, error: Error | AxiosError) => {
        // By default, useQuery performs 3 retries on network errors
        // No retry on 404 error; continue on other errors up to 3 times
        return axios.isAxiosError(error) && error?.response?.status === 404
          ? false
          : failureCount < 3
      },
    })

  const useGetNursingHome = (nursingHomeId?: number) =>
    useQuery({
      queryKey: ['nursing_home', nursingHomeId],
      queryFn: () => getNursingHome(nursingHomeId),
      enabled: !!nursingHomeId,
      retry: (failureCount, error: Error | AxiosError) => {
        return axios.isAxiosError(error) && error?.response?.status === 404
          ? false
          : failureCount < 3
      },
    })

  const useGetUsers = (disabled?: boolean) =>
    useQuery({
      queryKey: ['users'],
      queryFn: getUsers,
      enabled: !disabled,
      refetchOnMount: false,
    })

  const useGetUserOptions = ({
    withNoneOption,
    onlyActive,
  }: {
    withNoneOption?: boolean
    onlyActive?: boolean
  }) => {
    const { data: users } = useGetUsers()
    const opts = useMemo(() => {
      if (!users) return
      const out:
        | {
            label: string
            value: number
            original?: User
          }[]
        | undefined = users
        .filter((user: User) => {
          if (onlyActive) {
            return user.is_active
          }
          return true
        })
        .map((user: User) => ({
          label: user.first_name + ' ' + user.last_name,
          value: user.id,
          original: user,
        }))

      if (withNoneOption) {
        out.unshift({ label: 'No User', value: -1 })
      }

      return out
    }, [users, withNoneOption])

    return {
      data: opts,
      isLoading: !users,
      isError: false,
    }
  }

  async function bulkEditCompanyAttrs(values: {
    chain_ids?: number[]
    contact_company_ids?: number[]
    taglist?: 'REMOVE' | number[]
    distributors?: 'REMOVE' | number[]
    domain?: string
    previous_taglist?: number[]
    previous_distributors?: number[]
  }) {
    const url = 'contacts/companyattrs-bulk/'

    const [err, res] = await to(clientInstance.post(url, values))

    if (err) {
      notifyError(err.message)
      throw err
    }

    return res.data
  }

  async function bulkEditDeal(
    values: {
      deal: {
        account_owner_id?: number | null
        sales_stage_id?: number | null
        monthly_revenue_override?: number | null
        monthly_volume_override?: number | null
        close_date_override?: string | null
      }
    } & Omit<bulkEditSingleParams, 'item_id'>
  ) {
    const { params, ...rest } = values

    const [err, res] = await to(
      clientInstance.post('contacts/deals/bulk/', rest, {
        params,
      })
    )

    if (err) {
      notifyError(err.message)
      throw err
    }

    return res
  }

  async function getSalesPipeline(params = {}, signal?: AbortSignal) {
    return baseAPICall<SalesPipeline>(() =>
      clientInstance.get('products/sales_pipeline_v2/', {
        signal,
        params,
      })
    )
  }

  async function getSalesStages(signal?: AbortSignal) {
    return baseAPICall<SalesStage[]>(() =>
      clientInstance.get('products/sales_stages/', {
        signal,
      })
    )
  }

  const useGetSalesStages = (disabled?: boolean) =>
    useQuery({
      queryKey: ['stages'],
      queryFn: () => getSalesStages(),
      refetchOnMount: false,
      enabled: !disabled,
    })

  const useGetSalesStageOptions = (withNoneOption?: boolean) => {
    const { data } = useGetSalesStages()
    const out = useMemo(() => {
      if (!data) return
      const out:
        | {
            label: string
            value: number
            color: string
            original?: SalesStage
          }[]
        | undefined = data.map((s: SalesStage) => ({
        label: s.stage,
        value: s.id,
        color: getStageColor(s.win_rate)[0],
        original: s,
      }))

      if (withNoneOption) {
        out?.unshift({
          label: 'No Stage',
          value: -1,
          color: '#fff',
        })
      }

      return out
    }, [data, withNoneOption])

    return {
      data: out,
      isLoading: !data,
      isError: false,
    }
  }

  async function getTags(tagParent: tagParent = 'products') {
    return baseAPICall<Tag[]>(() => clientInstance.get(`${tagParent}/tags/`))
  }

  async function getProductTagsList(
    params: Record<string, string | number> = {},
    signal?: AbortSignal
  ) {
    return baseAPICall<PaginatedResponse<Tag>>(() =>
      clientInstance.get(`products/tags-list/`, {
        params: params,
        signal,
      })
    )
  }

  async function getContactTagsList(
    params: Record<string, string | number> = {},
    signal?: AbortSignal
  ) {
    return baseAPICall<PaginatedResponse<Tag>>(() =>
      clientInstance.get(`contacts/tags-list/`, {
        params: params,
        signal,
      })
    )
  }

  const useGetTags = (tagParent: tagParent = 'products', disabled?: boolean) =>
    useQuery({
      queryKey: ['tags', tagParent],
      queryFn: () => getTags(tagParent),
      enabled: !disabled,
      refetchOnMount: false,
    })

  const useGetTagsOptions = (
    withNoneOption?: boolean,
    tagParent: tagParent = 'products'
  ) => {
    const { data: tags } = useGetTags(tagParent)
    const opts = useMemo(() => {
      const opts:
        | {
            label: string
            value: number | string
            original?: Tag
          }[]
        | undefined = tags?.map((tag: any) => ({
        label: tag.tag,
        value: tag.id,
        original: tag,
      }))

      if (withNoneOption) {
        opts?.unshift({ label: 'No Tag', value: 'REMOVE' })
      }
      return opts
    }, [tags, withNoneOption])

    return {
      data: opts,
      isLoading: !tags,
      isError: false,
    }
  }

  async function postSalesStage(data: any) {
    return baseAPICall(() =>
      clientInstance.post('products/sales_stages/', data)
    )
  }

  async function postTag(data: any, tagParent: tagParent = 'products') {
    return baseAPICall(() =>
      clientInstance.post(`${tagParent}/tags/`, data)
    ).then((res) => {
      queryClient.invalidateQueries({ queryKey: ['tags', tagParent] })
      return res
    })
  }

  async function putTag(
    id: number,
    data: { tag: string; description: string; color: string },
    tagParent: tagParent = 'products'
  ) {
    return baseAPICall(() =>
      clientInstance.put(`${tagParent}/tags/${id}/`, data)
    )
  }

  async function putStage(id: number, data: any) {
    return baseAPICall(() =>
      clientInstance.put(`products/sales_stages/${id}/`, data)
    )
  }

  async function deleteStage(id: number) {
    return baseAPICall(() =>
      clientInstance.delete(`products/sales_stages/${id}/`)
    )
  }

  async function deleteTag(id: number, tagParent: tagParent = 'products') {
    return baseAPICall(() => clientInstance.delete(`${tagParent}/tags/${id}/`))
  }

  async function getDealsPipeline(params = {}, signal?: AbortSignal) {
    return baseAPICall<DealsPipeline[]>(() =>
      clientInstance.get('products/deals-pipeline/', {
        signal,
        params,
      })
    )
  }

  async function getChainsListV2(params = {}, signal?: AbortSignal) {
    return baseAPICall<ChainListResponse>(() =>
      clientInstance.get('restaurants/chains/list/', {
        signal,
        params,
      })
    )
  }

  async function getChainMenuList(
    chainId?: number,
    params = {},
    signal?: AbortSignal
  ) {
    if (!chainId) {
      return [] as Menu[]
    }
    return baseAPICall<Menu[]>(() =>
      clientInstance.get(`restaurants/chains/${chainId}/menu/`, {
        signal,
        params,
      })
    )
  }

  const useGetChainMenuList = (chainId?: number, params = {}) =>
    useQuery({
      queryKey: ['chain_menu_list', chainId, params],
      queryFn: () => getChainMenuList(chainId, params),
      refetchOnMount: false,
      enabled: !!chainId,
    })

  async function getDoorsMenuList(
    doorId?: number,
    params = {},
    signal?: AbortSignal
  ) {
    if (!doorId) {
      return [] as Menu[]
    }
    return baseAPICall<Menu[]>(() =>
      clientInstance.get(`restaurants/places/${doorId}/menu/`, {
        signal,
        params,
      })
    )
  }

  const useGetDoorsMenuList = (doorId?: number, params = {}) =>
    useQuery({
      queryKey: ['doors_menu_list', doorId, params],
      queryFn: () => getDoorsMenuList(doorId, params),
      refetchOnMount: false,
      enabled: !!doorId,
    })

  async function getContactsList(
    params = {},
    signal?: AbortSignal
  ): Promise<ContactListResponse> {
    return baseAPICall<ContactListResponse>(() =>
      clientInstance.get('contacts/', {
        signal,
        params,
      })
    )
  }

  async function useGetContactsList(
    params = {},
    signal?: AbortSignal,
    enabled = true
  ) {
    return useQuery({
      queryKey: ['contacts', params],
      queryFn: () => getContactsList(params, signal),
      refetchOnMount: false,
      enabled,
    })
  }

  async function getActivityList(
    params = {},
    signal?: AbortSignal
  ): Promise<ActivityListResponse> {
    return baseAPICall<ActivityListResponse>(() =>
      clientInstance.get('activity/team/', {
        signal,
        params,
      })
    )
  }

  async function getMyActivityList(
    params = {},
    signal?: AbortSignal
  ): Promise<ActivityListResponse> {
    return baseAPICall<ActivityListResponse>(() =>
      clientInstance.get('activity/my/', {
        signal,
        params,
      })
    )
  }

  async function getActivityListV2(
    params = {},
    signal?: AbortSignal
  ): Promise<ActivityListResponseV2> {
    return baseAPICall<ActivityListResponseV2>(() =>
      clientInstance.get('activity/v2/team/', {
        signal,
        params,
      })
    )
  }

  async function getMyActivityListV2(
    params = {},
    signal?: AbortSignal
  ): Promise<ActivityListResponseV2> {
    return baseAPICall<ActivityListResponseV2>(() =>
      clientInstance.get('activity/v2/my/', {
        signal,
        params,
      })
    )
  }

  async function searchCompaniesList(
    params: any = {},
    signal?: AbortSignal
  ): Promise<ContactCompanySearchResult[]> {
    return baseAPICall(() =>
      clientInstance.get('contacts/companies-search/', {
        signal,
        params,
      })
    )
  }

  async function searchCompaniesUniqueDomain(
    domain: string,
    signal?: AbortSignal
  ): Promise<ContactCompanySearchResult[]> {
    return baseAPICall(() =>
      clientInstance.get('contacts/companies-search/domain', {
        signal,
        params: { domain: domain },
      })
    )
  }

  async function getCompanyCategoriesAndSub() {
    type CompanyCategoriesAndSubcategories = {
      id: number
      name: string
      slug: CompanyCategorySlug
      description: string
      sub_categories: {
        id: number
        name: string
        slug: CompanyTypeSlug
        description: string
      }[]
    }[]

    const res = await baseAPICall<CompanyCategoriesAndSubcategories>(() =>
      clientInstance.get('contacts/companies-by-category/')
    )

    res.sort((a, b) => a.name.charCodeAt(0) - b.name.charCodeAt(0))

    return res
  }

  const useGetCompanyCategoriesAndSub = (disabled = false) =>
    useQuery({
      queryKey: ['companyCategoriesAndSub'],
      queryFn: () => getCompanyCategoriesAndSub(),
      enabled: !disabled,
      refetchOnMount: false,
    })

  async function createContact(data: ContactCreateRequest) {
    return baseAPICall(() => clientInstance.post('contacts/create/', data))
  }

  async function updateContact(id: number, data: ContactCreateRequest) {
    return baseAPICall(() =>
      clientInstance.patch(`contacts/update/${id}/`, data)
    )
  }

  async function deleteContact(id: number) {
    return baseAPICall(() => clientInstance.delete(`contacts/${id}/`))
  }

  async function downloadDoorsCsvV2(
    params = {},
    selectedColumns: string[],
    exportType: 'csv' | 'xlsx',
    ids: number[] | undefined,
    excludeIds: number[] | undefined,
    signal?: AbortSignal
  ) {
    return baseAPICall(() =>
      clientInstance.post(
        'restaurants/places/export/',
        {
          selected_columns: selectedColumns,
          export_type: exportType,
          include_ids: ids,
          exclude_ids: excludeIds,
        },
        {
          responseType: 'blob',
          signal,
          params,
        }
      )
    )
  }

  async function downloadDoorsCsvByProduct(
    params = {},
    selectedColumns: string[],
    exportType: 'csv' | 'xlsx',
    signal?: AbortSignal
  ) {
    return baseAPICall(() =>
      clientInstance.post(
        'restaurants/places/export/ltv-by-product/',
        {
          selected_columns: selectedColumns,
          export_type: exportType,
        },
        {
          responseType: 'blob',
          signal,
          params,
        }
      )
    )
  }

  async function downloadChainsCsvV2(
    params = {},
    selectedColumns: string[],
    exportType: 'csv' | 'xlsx',
    ids: number[] | undefined,
    excludeIds: number[] | undefined,
    signal?: AbortSignal
  ) {
    return baseAPICall(() =>
      clientInstance.post(
        'restaurants/chains/export/',

        {
          selected_columns: selectedColumns,
          export_type: exportType,
          include_ids: ids,
          exclude_ids: excludeIds,
        },
        {
          responseType: 'blob',
          signal,
          params,
        }
      )
    )
  }

  async function downloadCampaignsCsv(
    params = {},
    selectedColumns: string[],
    exportType: 'csv' | 'xlsx',
    ids: number[] | undefined,
    excludeIds: number[] | undefined,
    signal?: AbortSignal
  ) {
    return baseAPICall(() =>
      clientInstance.post(
        'contacts/campaigns/export/',
        {
          selected_columns: selectedColumns,
          export_type: exportType,
          id: ids,
          exclude_ids: excludeIds,
        },
        {
          responseType: 'blob',
          signal,
          params,
        }
      )
    )
  }

  async function exportContactCompanies(
    params = {},
    selectedColumns: string[],
    exportType: 'csv' | 'xlsx',
    signal?: AbortSignal
  ) {
    return baseAPICall(() =>
      clientInstance.post(
        'contacts/contactcompanies/export/',
        {
          selected_columns: selectedColumns,
          export_type: exportType,
        },
        {
          responseType: 'blob',
          signal,
          params,
        }
      )
    )
  }

  async function exportDeals(
    params = {},
    selectedColumns: string[],
    exportType: 'csv' | 'xlsx',
    signal?: AbortSignal
  ) {
    return baseAPICall(() =>
      clientInstance.post(
        'contacts/deals/export/',
        {
          selected_columns: selectedColumns,
          export_type: exportType,
        },
        {
          responseType: 'blob',
          signal,
          params,
        }
      )
    )
  }

  async function exportUniversities(
    params = {},
    selectedColumns: string[],
    exportType: 'csv' | 'xlsx',
    ids: number[] | undefined,
    excludeIds: number[] | undefined,
    signal?: AbortSignal
  ) {
    return baseAPICall(() =>
      clientInstance.post(
        'universities/export/',
        {
          selected_columns: selectedColumns,
          export_type: exportType,
          include_ids: ids,
          exclude_ids: excludeIds,
        },
        {
          responseType: 'blob',
          signal,
          params,
        }
      )
    )
  }

  function exportHospitals(
    params = {},
    selectedColumns: string[],
    exportType: 'csv' | 'xlsx',
    ids: number[] | undefined,
    excludeIds: number[] | undefined,
    signal?: AbortSignal
  ) {
    return baseAPICall(() =>
      clientInstance.post(
        'hospital/export/',
        {
          selected_columns: selectedColumns,
          export_type: exportType,
          include_ids: ids,
          exclude_ids: excludeIds,
        },
        {
          responseType: 'blob',
          signal,
          params,
        }
      )
    )
  }

  async function exportContacts(
    params = {},
    selectedColumns: string[],
    exportType: 'csv' | 'xlsx',
    signal?: AbortSignal
  ) {
    return baseAPICall(() =>
      clientInstance.post(
        'contacts/export/',
        {
          selected_columns: selectedColumns,
          export_type: exportType,
        },
        {
          responseType: 'blob',
          signal,
          params,
        }
      )
    )
  }

  async function exportDealsPipeline(
    params = {},
    selectedColumns: string[],
    exportType: 'csv' | 'xlsx',
    signal?: AbortSignal
  ) {
    return baseAPICall(() =>
      clientInstance.post(
        'products/deals-pipeline/export/',
        {
          selected_columns: selectedColumns,
          export_type: exportType,
        },
        {
          responseType: 'blob',
          signal,
          params,
        }
      )
    )
  }

  async function downloadActivityExport(params = {}, signal?: AbortSignal) {
    return baseAPICall(() =>
      clientInstance.get('activity/team/export/', {
        responseType: 'blob',
        signal,
        params,
      })
    )
  }

  async function downloadMyActivityExport(params = {}, signal?: AbortSignal) {
    return baseAPICall(() =>
      clientInstance.get('activity/my/export/', {
        responseType: 'blob',
        signal,
        params,
      })
    )
  }

  async function downloadActivityExportV2(params = {}, signal?: AbortSignal) {
    return baseAPICall(() =>
      clientInstance.get('activity/v2/team/export/', {
        responseType: 'blob',
        signal,
        params,
      })
    )
  }

  async function downloadMyActivityExportV2(params = {}, signal?: AbortSignal) {
    return baseAPICall(() =>
      clientInstance.get('activity/v2/my/export/', {
        responseType: 'blob',
        signal,
        params,
      })
    )
  }

  function getContactsImportFormData(
    file: File,
    column_mapping: any,
    company_mapping: any = null,
    tags: number[] = [],
    campaigns: ContactCampaign[] = []
  ): FormData {
    const formData = new FormData()
    formData.append('file', file)
    const jsonBlob = new Blob([JSON.stringify(column_mapping)], {
      type: 'application/json',
    })
    formData.append('column_mapping', jsonBlob)
    if (company_mapping) {
      const jsonBlob = new Blob([JSON.stringify(company_mapping)], {
        type: 'application/json',
      })
      formData.append('company_mapping', jsonBlob)
    }
    if (tags.length > 0) {
      const jsonBlob = new Blob([JSON.stringify(tags)], {
        type: 'application/json',
      })
      formData.append('tags', jsonBlob)
    }
    if (campaigns.length > 0) {
      const jsonBlob = new Blob([JSON.stringify(campaigns)], {
        type: 'application/json',
      })
      formData.append('campaigns', jsonBlob)
    }
    return formData
  }

  async function previewContactsImport(
    file: File,
    column_mapping: any,
    company_mapping: any = null
  ): Promise<ContactsImportResponse> {
    const formData = getContactsImportFormData(
      file,
      column_mapping,
      company_mapping
    )
    return baseAPICall(() =>
      clientInstance.post('contacts/import/preview/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    )
  }

  async function contactsImport(
    file: File,
    column_mapping: any,
    company_mapping: any = null,
    tags: number[] = [],
    campaigns: ContactCampaign[] = []
  ): Promise<ContactsImportResponse> {
    const formData = getContactsImportFormData(
      file,
      column_mapping,
      company_mapping,
      tags,
      campaigns
    )
    return baseAPICall(() =>
      clientInstance.post('contacts/import/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    )
  }

  async function getCompanyCategories() {
    return baseAPICall<{
      company_categories: Category[]
    }>(() => clientInstance.get('contacts/categories/'))
  }

  const useGetCompanyCategories = (enabled = true) =>
    useQuery({
      queryKey: ['company_categories'],
      queryFn: getCompanyCategories,
      enabled: enabled,
      refetchOnMount: false,
    })

  async function getCompaniesList(subCategory: number, params = {}) {
    return baseAPICall<PaginatedResponse<IContactCompany>>(() =>
      clientInstance.get(`/contacts/contactcompanies/`, {
        params: {
          sub_category_id: subCategory,
          ...params,
        },
      })
    )
  }

  async function getCompany(id: number): Promise<IContactCompany> {
    return baseAPICall(() =>
      clientInstance.get(`contacts/contactcompanies/${id}/`)
    )
  }

  const useGetCompany = (companyId: number) =>
    useQuery<IContactCompany, AxiosError>({
      queryKey: ['company', companyId],
      queryFn: () => getCompany(companyId),
      enabled: !!companyId,
      retry: (failureCount, error: AxiosError) => {
        return axios.isAxiosError(error) && error?.response?.status === 404
          ? false
          : failureCount < 3
      },
    })

  async function createCompany(
    data: IContactCompanyCreateRequest
  ): Promise<IContactCompany> {
    return baseAPICall(
      () => clientInstance.post(`contacts/contactcompanies/`, data),
      { disableNotifyGenericError: true }
    )
  }

  async function createDoorProxy(
    data: IDoorProxyCreateRequest
  ): Promise<IContactCompany> {
    return baseAPICall(() => clientInstance.post(`contacts/doorproxies/`, data))
  }

  async function editDoorProxy(
    data: IDoorProxyEditRequest,
    doorProxyId: number
  ): Promise<IDoorProxy> {
    return baseAPICall<IDoorProxy>(() =>
      clientInstance.patch(`contacts/doorproxies/${doorProxyId}/`, data)
    )
  }

  async function updateCompany(
    id: number,
    data: IContactCompanyUpdateRequest
  ): Promise<IContactCompany> {
    return baseAPICall(() =>
      clientInstance.patch(`contacts/contactcompanies/${id}/`, data)
    )
  }

  async function getCompanyDistributorsOptions(withNoneOption?: boolean) {
    return baseAPICall<
      {
        label: string
        value: number
        original: CompanyDistributor
      }[]
    >(() =>
      clientInstance.get('contacts/companies/distributors/', {
        transformResponse: (data) => {
          const distributors = JSON.parse(data)
          const opts = distributors.map((distributor: CompanyDistributor) => ({
            label: distributor.name,
            value: distributor.id,
            original: distributor,
          }))

          if (withNoneOption) {
            opts.unshift({ label: 'No Distributor', value: -1 })
          }

          return opts
        },
      })
    )
  }

  async function getUniversityFoodManagementCompanyOptions(
    withNoneOption?: boolean
  ) {
    return baseAPICall<{
      food_management_companies: any[]

      parent_food_management_companies: any[]
    }>(() =>
      clientInstance.get('universities/food_management_companies', {
        transformResponse: (data) => {
          const companies = JSON.parse(data)
          const options = {
            food_management_companies: [],
            parent_food_management_companies: [],
          } as any

          companies.food_management_companies.forEach((company: string) => {
            options.food_management_companies.push({
              label: company,
              value: company,
            })
          })

          companies.parent_food_management_companies.forEach(
            (parentCompany: string) => {
              options.parent_food_management_companies.push({
                label: parentCompany,
                value: parentCompany,
              })
            }
          )

          if (withNoneOption) {
            options.food_management_companies.unshift({
              label: 'No Company',
              value: '',
            })
            options.parent_food_management_companies.unshift({
              label: 'No Company',
              value: '',
            })
          }

          return options
        },
      })
    )
  }

  const useGetDistributors = () =>
    useQuery({
      queryKey: ['base-distributors'],
      queryFn: () => getDistributors(),
      refetchOnMount: false,
    })

  const useGetCompanyDistributorsOptions = (withNoneOption?: boolean) =>
    useQuery({
      queryKey: ['distributors', withNoneOption ? 'with-none' : ''],
      queryFn: () => getCompanyDistributorsOptions(withNoneOption),
      refetchOnMount: false,
    })

  const useGetUniversityFoodManagementCompanyOptions = (
    withNoneOption?: boolean
  ) =>
    useQuery({
      queryKey: [
        'food_management_companies',
        withNoneOption ? 'with-none' : '',
      ],
      queryFn: () => getUniversityFoodManagementCompanyOptions(withNoneOption),
      refetchOnMount: false,
    })

  const postDistributor = async (data: CompanyDistributorCreate) => {
    return baseAPICall(() =>
      clientInstance.post(`contacts/companies/distributors/`, data)
    )
  }

  const updateDistributor = async (
    id: number,
    data: Partial<CompanyDistributorCreate>
  ) => {
    return baseAPICall(() =>
      clientInstance.patch(`contacts/companies/distributors/${id}/`, data)
    )
  }

  const deleteDistributor = async (id: number) => {
    return baseAPICall(() =>
      clientInstance.delete(`contacts/companies/distributors/${id}/`)
    )
  }

  async function getDistributors({
    signal,
    params = {},
  }: { signal?: AbortSignal; params?: Record<string, unknown> } = {}) {
    return baseAPICall<CompanyDistributor[]>(() =>
      clientInstance.get('contacts/companies/distributors/', {
        params,
        signal,
      })
    )
  }

  const getProductPredictions = async ({
    chainProxyId,
  }: {
    chainProxyId?: number
  }): Promise<ProductPrediction[]> => {
    return baseAPICall<ProductPrediction[]>(() =>
      clientInstance.get(`products/predictions/`, {
        params: {
          chain_proxy_id: chainProxyId,
        },
      })
    )
  }

  const useGetProductPredictions = ({
    chainProxyId,
    enabled = true,
  }: {
    chainProxyId?: number
    enabled?: boolean
  }) =>
    useQuery({
      queryKey: ['product_predictions', chainProxyId],
      queryFn: () => getProductPredictions({ chainProxyId }),
      enabled: enabled,
    })

  async function getDeals(params = {}, signal?: AbortSignal) {
    return baseAPICall<PaginatedResponse<Deal>>(() =>
      clientInstance.get(`contacts/deals/`, {
        params,
        signal,
      })
    )
  }

  async function getNotes(
    chain_id: number | undefined,
    contact_company_id: number | undefined,
    params = {},
    signal?: AbortSignal
  ) {
    return baseAPICall<PaginatedResponse<INote>>(() =>
      clientInstance.get(`contacts/contactcompanies/notes/`, {
        params: {
          ...params,
          chain_id: chain_id,
          contact_company_id: contact_company_id,
        },
        signal,
      })
    )
  }

  async function editNote(id: number, content: string) {
    return baseAPICall(() =>
      clientInstance.patch(`contacts/contactcompanies/notes/${id}/`, {
        content: content,
      })
    )
  }

  async function createNote(
    chain_id: number | undefined,
    contact_company_id: number | undefined,
    content?: string,
    attachments?: File[]
  ): Promise<INote> {
    return baseAPICall(() => {
      const formData = new FormData()
      formData.append('content', content || '')
      formData.append('chain_id', chain_id?.toString() || '')
      formData.append(
        'contact_company_id',
        contact_company_id?.toString() || ''
      )
      if (attachments) {
        attachments.forEach((file) => {
          formData.append('attachments', file)
        })
      }
      return clientInstance.post(`contacts/contactcompanies/notes/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    })
  }

  async function deleteNote(id: number) {
    return baseAPICall(() =>
      clientInstance.delete(`contacts/contactcompanies/notes/${id}/`)
    )
  }

  const useGetDeals = (params = {}) =>
    useQuery({
      queryKey: ['deals', JSON.stringify(params)],
      queryFn: () => getDeals(params),
    })

  const editDeal = async (dealId: number, data: DealEdit) => {
    const formData = new FormData()
    if (data.sales_stage) {
      formData.append('sales_stage', data.sales_stage.toString())
    }
    if (data.account_owner) {
      formData.append('account_owner', data.account_owner.toString())
    }
    if (data.monthly_volume_override) {
      formData.append(
        'monthly_volume_override',
        data.monthly_volume_override.toString()
      )
    }
    if (data.monthly_revenue_override) {
      formData.append(
        'monthly_revenue_override',
        data.monthly_revenue_override.toString()
      )
    }
    if (data.close_date_override) {
      formData.append('close_date_override', data.close_date_override)
    }
    if (data.notes) {
      formData.append('notes', data.notes)
    }
    if (data.origin_campaign_id) {
      formData.append('origin_campaign_id', data.origin_campaign_id)
    }
    if (data.attachments) {
      data.attachments.forEach((file) => {
        formData.append('attachments', file)
      })
    }

    return baseAPICall(() =>
      clientInstance.patch(`contacts/deals/${dealId}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    )
  }

  async function deleteDeal(id: number) {
    return baseAPICall(() => clientInstance.delete(`contacts/deals/${id}/`))
  }

  const useProducts = (disabled?: boolean) =>
    useQuery({
      queryKey: ['product'],
      queryFn: async () => {
        return await getProducts()
      },
      enabled: !disabled,
    })

  const deleteCompany = async (id: number) => {
    return baseAPICall(() =>
      clientInstance.delete(`contacts/contactcompanies/${id}/`)
    )
  }

  const getDealHistory = async (dealId: number): Promise<DealHistory[]> => {
    return baseAPICall<DealHistory[]>(() =>
      clientInstance.get(`contacts/deals/${dealId}/history/`)
    )
  }

  const useGetDealHistory = ({ dealId }: { dealId: number }) => {
    return useQuery({
      queryKey: ['deal_history', dealId],
      queryFn: () => getDealHistory(dealId),
    })
  }

  async function getPreferences() {
    return baseAPICall<Preferences>(() => clientInstance.get('preferences/'))
  }

  const useGetPreferences = (disabled?: boolean) =>
    useQuery({
      queryKey: ['preferences'],
      queryFn: getPreferences,
      refetchOnMount: false,
      enabled: !disabled,
    })

  async function getUnreadNotificationsCount() {
    return baseAPICall<{
      count: number
    }>(() => clientInstance.get('notifications/unread-count/'))
  }

  const useGetUnreadNotificationsCount = () =>
    useQuery({
      queryKey: ['unread-notifications'],
      queryFn: getUnreadNotificationsCount,
      refetchOnMount: true,
      refetchInterval: 10000,
    })

  async function getNotifications({ unread }: { unread?: boolean } = {}) {
    return baseAPICall<Notification[]>(() =>
      clientInstance.get('notifications/', {
        params: {
          is_read: !unread,
        },
      })
    )
  }

  const useGetNotifications = ({ unread }: { unread?: boolean } = {}) =>
    useQuery({
      queryKey: ['notifications', unread],
      queryFn: () => getNotifications({ unread }),
      refetchOnMount: true,
    })

  async function markNotificationAsRead(ids: number[]) {
    return baseAPICall<Notification>(() =>
      clientInstance.post(`notifications/mark-as-read/`, {
        notification_ids: ids,
      })
    )
  }

  async function markAllNotificationsAsRead() {
    return baseAPICall<Notification>(() =>
      clientInstance.post(`notifications/mark-all-as-read/`)
    )
  }

  function getDashboardOverview() {
    return baseAPICall(() =>
      clientInstance.get('products/dashboard/overview/')
    ) as Promise<
      {
        title: string
        value: string
        tooltip: string
      }[]
    >
  }

  const useGetDashboardOverview = () => {
    return useQuery({
      queryKey: ['dashboard_overview'],
      queryFn: getDashboardOverview,
    })
  }

  function getDashboardSalesForecast(
    timeHorizon = 12,
    accountOwnerIds?: number[],
    productIds?: number[],
    stageIds?: number[],
    subCatIds?: number[],
    campaignIds?: number[]
  ) {
    return baseAPICall(() =>
      clientInstance.get('products/dashboard/sales_forecast/', {
        params: {
          time_horizon: timeHorizon,
          account_owner_ids: accountOwnerIds,
          product_ids: productIds,
          stage_ids: stageIds,
          sub_category_ids: subCatIds,
          campaign_ids: campaignIds,
        },
      })
    ) as Promise<{
      charts: {
        [key: string]: {
          labels: string[]
          values: number[]
        }
      }
      cards: { label: string; value: string; tooltip?: string }[]
    }>
  }

  const useGetDashboardSalesForecast = (
    timeHorizon = 12,
    accountOwnerIds?: number[],
    productIds?: number[],
    stageIds?: number[],
    subCatIds?: number[],
    campaignIds?: number[]
  ) => {
    return useQuery({
      queryKey: [
        'sales_forecast',
        timeHorizon,
        accountOwnerIds,
        productIds,
        stageIds,
        subCatIds,
        campaignIds,
      ],
      queryFn: () =>
        getDashboardSalesForecast(
          timeHorizon,
          accountOwnerIds,
          productIds,
          stageIds,
          subCatIds,
          campaignIds
        ),
    })
  }

  async function saveFilters(payload: SavedViewCreate) {
    return baseAPICall<Notification[]>(
      () =>
        clientInstance.post('saved-views/', {
          ...payload,
        }),
      { disableNotifyError: true }
    )
  }

  async function deleteFilter(filterId: number) {
    return baseAPICall(() => clientInstance.delete(`saved-views/${filterId}`), {
      disableNotifyError: true,
    })
  }

  async function getSavedFiltersOptions(identifier: FilterIdentifier) {
    return baseAPICall<
      {
        label: string
        value: number
        original: SavedView
      }[]
    >(() =>
      clientInstance.get('saved-views/', {
        params: {
          identifier,
        },
        transformResponse: (data) => {
          const savedViews = JSON.parse(data)
          return savedViews.map((savedView: SavedView) => ({
            label: savedView.name,
            value: savedView.id,
            original: savedView,
          }))
        },
      })
    )
  }

  async function getAllSavedFilters() {
    return baseAPICall<SavedView[]>(() => clientInstance.get('saved-views/'))
  }

  const useGetAllSavedFilters = () => {
    return useQuery({
      queryKey: ['saved-views/'],
      queryFn: () => getAllSavedFilters(),
    })
  }

  const useGetSavedFiltersOptions = (identifier: FilterIdentifier) => {
    return useQuery({
      queryKey: ['saved_filters', identifier],
      queryFn: () => getSavedFiltersOptions(identifier),
    })
  }

  const postSuggestion = async (data: SuggestionCreate) => {
    return baseAPICall(() =>
      clientInstance.post('restaurants/suggestions/', data)
    )
  }

  async function getDoorProxiesOfChainProxy(chainProxyId?: number) {
    if (!chainProxyId) {
      return null
    }

    return baseAPICall<IDoorProxyListResponse>(() =>
      clientInstance.get(`contacts/contactcompanies/${chainProxyId}/doors/`)
    )
  }

  const useDoorProxiesOfChainProxy = (chainProxyId?: number) =>
    useQuery({
      queryKey: ['door_proxies_of_chain_proxy', chainProxyId],
      queryFn: () => getDoorProxiesOfChainProxy(chainProxyId),
      refetchOnMount: false,
    })

  function deleteDoorProxy(doorProxyId: number) {
    return clientInstance.delete(`contacts/doorproxies/${doorProxyId}/`)
  }

  async function getUnmatchedCompanyCities(
    subCategoryId: number,
    signal?: AbortSignal
  ) {
    return baseAPICall<PaginatedResponse<Tag>>(() =>
      clientInstance.get(`contacts/contactcompanies/cities/`, {
        params: {
          sub_category_id: subCategoryId,
        },
        signal,
      })
    )
  }

  const useGetUnmatchedCompanyCities = (subCategoryId: number) =>
    useQuery({
      queryKey: ['contact_company_cities', subCategoryId],
      queryFn: () => getUnmatchedCompanyCities(subCategoryId),
      refetchOnMount: false,
    })

  type ChangeMiscSettingsRequest = {
    key: MISC_SETTINGS_TYPES
    value: unknown
  }

  function changeMiscSettings(args: ChangeMiscSettingsRequest) {
    return baseAPICall(() => {
      return clientInstance.post(
        `preferences/misc-settings/update/${args.key}/`,
        {
          key: args.key,
          value: args.value,
        }
      )
    })
  }

  const useChangeMiscSettings = () => {
    return useMutation({
      mutationFn: (variables: ChangeMiscSettingsRequest) =>
        changeMiscSettings(variables),
      onMutate: async (newSetting) => {
        const prev = queryClient.getQueriesData({
          queryKey: ['preferences'],
        })

        queryClient.setQueriesData(
          { queryKey: ['preferences'] },
          (old?: Preferences) => {
            if (!old) {
              return
            }
            let data = structuredClone(old)
            const miscSettings = data.misc_settings.filter(
              (it) => it.key !== newSetting.key
            )
            miscSettings.push({ key: newSetting.key, value: newSetting.value })
            data = {
              ...data,
              misc_settings: miscSettings,
            }
            return data
          }
        )

        return { prev }
      },
      onError: (_, __, context) => {
        queryClient.setQueryData(['preferences'], context?.prev)
        queryClient.setQueriesData(
          {
            queryKey: ['preferences'],
          },
          context?.prev
        )
      },
    })
  }

  const addAttachmentsToNote = async (
    noteId: number,
    attachments: File[]
  ): Promise<INote> => {
    const formData = new FormData()
    attachments.forEach((file) => {
      formData.append('attachments', file)
    })

    return baseAPICall(() =>
      clientInstance.post(
        `contacts/contactcompanies/notes/${noteId}/attachments/`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
    )
  }

  const deleteNoteAttachment = async (noteId: number, attachmentId: number) => {
    return baseAPICall(() =>
      clientInstance.delete(
        `contacts/contactcompanies/notes/${noteId}/attachments/${attachmentId}/`
      )
    )
  }

  const getAsyncJobs = async (params = {}) => {
    return baseAPICall<PaginatedResponse<AsyncJobListResponse>>(() =>
      clientInstance.get('async-jobs/', {
        params,
      })
    )
  }

  const useGetAsyncJobs = (params = {}) =>
    useQuery({
      queryKey: ['async-jobs', JSON.stringify(params)],
      queryFn: () => getAsyncJobs(params),
    })

  const contactStarToggle = async (contactId: number) => {
    return baseAPICall<boolean>(() =>
      clientInstance.patch(`contacts/startoggle/${contactId}/`)
    )
  }

  function bulkDeleteDeals(
    dealIDs: number[],
    allRowsSelected: boolean,
    filterParams: any
  ) {
    return baseAPICall(() =>
      clientInstance.post(
        `contacts/deals/bulk/delete/`,
        {
          include_ids: allRowsSelected ? [] : dealIDs,
          exclude_ids: allRowsSelected ? dealIDs : [],
        },
        {
          params: filterParams,
        }
      )
    )
  }
  const inviteUser = async (email: string) => {
    return baseAPICall<boolean>(() =>
      clientInstance.post(`invite-user/`, {
        email: email,
      })
    )
  }

  async function getStripePortalRedirect(returnUrl: string) {
    return baseAPICall(() =>
      clientInstance.get(`products/stripe-portal-redirect/`, {
        params: {
          return_url: returnUrl,
        },
      })
    )
  }

  async function getTextContent() {
    return baseAPICall(() => clientInstance.get(`common/text-content/`))
  }

  const useGetTextContent = () =>
    useQuery({
      queryKey: ['text-content'],
      queryFn: () => getTextContent(),
    })

  type DataRequestSubmission = {
    first_name: string
    last_name: string
    email: string
    data_request_type: string
  }

  async function dataRequestSubmission(data: DataRequestSubmission) {
    return baseAPICall(() =>
      clientInstance.post(`common/data-request-submission/`, data)
    )
  }

  type OptOutSubmission = {
    first_name: string
    last_name: string
    email: string
    do_not_sell: boolean
  }

  async function optOutSubmission(data: OptOutSubmission) {
    return baseAPICall(() =>
      clientInstance.post(`common/opt-out-submission/`, data)
    )
  }

  return {
    postProduct,
    putProduct,
    deleteProduct,
    getRestaurantsV2,
    getPlaceV2,
    useGetPlaceV2,
    getChain,
    getUsers,
    useGetUsers,
    bulkEditCompanyAttrs,
    getSalesPipeline,
    getSalesStages,
    getTags,
    postSalesStage,
    postTag,
    putTag,
    putStage,
    deleteStage,
    deleteTag,
    useGetSalesStages,
    useGetTags,
    getChainsListV2,
    useGetProducts,
    useGetTagsOptions,
    useGetUserOptions,
    useGetSalesStageOptions,
    useGetPlacesSummaryV2,
    useGetChainsSummaryV2,
    downloadDoorsCsvV2,
    downloadChainsCsvV2,
    downloadCampaignsCsv,
    getContactsList,
    useGetContactsList,
    searchCompaniesList,
    createContact,
    deleteContact,
    updateContact,
    previewContactsImport,
    contactsImport,
    useGetCompanyCategories,
    useGetCompanyCategoriesAndSub,
    getCompaniesList,
    getCompany,
    useGetCompany,
    createCompany,
    updateCompany,
    useGetCompanyDistributorsOptions,
    useGetUniversityFoodManagementCompanyOptions,
    getUniversitySummary,
    useGetProductPredictions,
    getDeals,
    useGetDeals,
    commonCreateDeals,
    bulkEditDeal,
    deleteDeal,
    useProducts,
    editDeal,
    deleteCompany,
    useGetProductsOptions,
    useGetDealHistory,
    downloadDoorsCsvByProduct,
    exportDeals,
    exportContacts,
    downloadActivityExport,
    downloadMyActivityExport,
    getDealsPipeline,
    useGetPreferences,
    getActivityList,
    getMyActivityList,
    getNotes,
    editNote,
    deleteNote,
    createNote,
    useGetChain,
    useGetUnreadNotificationsCount,
    useGetNotifications,
    markNotificationAsRead,
    markAllNotificationsAsRead,
    useGetDashboardOverview,
    saveFilters,
    useGetSavedFiltersOptions,
    useGetDashboardSalesForecast,
    getChainMenuList,
    useGetChainMenuList,
    useGetUniversity,
    getUniversitiesList,
    postSuggestion,
    getDoorsMenuList,
    useGetDoorsMenuList,
    getActivityListV2,
    exportDealsPipeline,
    postDistributor,
    updateDistributor,
    getDistributors,
    deleteDistributor,
    exportContactCompanies,
    useDoorProxiesOfChainProxy,
    createDoorProxy,
    deleteDoorProxy,
    editDoorProxy,
    exportUniversities,
    getK12DistrictsList,
    useGetK12District,
    exportK12Districts,
    getK12DistrictsSummary,
    useK12CountiesOptions,
    searchCompaniesUniqueDomain,
    getProductTagsList,
    getContactTagsList,
    getMyActivityListV2,
    useGetUnmatchedCompanyCities,
    useGetDistributors,
    downloadActivityExportV2,
    downloadMyActivityExportV2,
    createContactRequestJob,
    fetchContactRequestJobs,
    fetchContactRequestJobDetails,
    useChangeMiscSettings,
    deleteNoteAttachment,
    addAttachmentsToNote,
    useGetAllSavedFilters,
    useGetCampaigns,
    useEditCampaign,
    useCreateCampaign,
    useDeleteCampaign,
    useAssignToCampaignMutation,
    useUnassignToCampaignMutation,
    deleteFilter,
    useGetAsyncJobs,
    fetchRequestedContacts,
    useRequestContactsFromCampaign,
    useGetCampaignsOptions,
    useGetHospital,
    getHospitalsList,
    exportHospitals,
    getNursingHome,
    exportNursingHomes,
    getNursingHomesList,
    getNursingHomesSummary,
    useGetNursingHome,
    deleteColumnPreset,
    createColumnPreset,
    useGetAllColumnPresets,
    useGetColumnPresetOptions,
    contactStarToggle,
    useGetRebateOffer,
    getRebateOffers,
    createRebateOffer,
    getRebateSuggestions,
    useGetRebateClaims,
    useGetRebateEnrollments,
    useGetRebateEnrollmentDetails,
    useManufacturerInvoices,
    useGetCurrentManufacturerInvoice,
    useGetRebateOfferOptions,
    getRebateDashboardStats,
    useGetRebateDashboardStats,
    bulkDeleteDeals,
    inviteUser,
    useGetCampaignCompanies,
    getStripePortalRedirect,
    getLodgingList,
    getLodgingDoorList,
    useGetLodging,
    useGetLodgingDoor,
    exportLodging,
    getCampaigns,
    exportLodgingDoors,
    getLodgingSummary,
    getLodgingDoorSummary,
    getHospitalsSummary,
    emailIntegration: {
      getEmailIntegrationList,
      useGetEmailIntegrationOptions,
      getEmailIntegrationAuthUrl,
      updateEmailIntegration,
      deleteEmailIntegration,
      getEmailMessageList,
      syncEmailIntegration,
      sendEmailMessage,
      getEmailMessageById,
    },
    commonManageTags,
    commonManageDistributors,
    useGetTextContent,
    getAttributionCodes,
    useGetAttributionCodes,
    createAttributionCode,
    updateAttributionCode,
    deleteAttributionCode,
    dataRequestSubmission,
    optOutSubmission,
  }
}
