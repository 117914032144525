import { createColumnHelper } from '@tanstack/react-table'
import { format } from 'date-fns'
import { useMemo, useState } from 'react'
import { usePreferences } from '../../context/preferences/PreferencesContext'
import { AttributionCode } from './tradespend_api'
import { ATTRIBUTION_CODES_COLUMNS } from './attributionCodesColumnInfo'
import { OverflownText } from '../../components/OverflownText'
import { AttributionCodeCell } from '../../components/FbUI/StagePill'
import { BiCopy } from 'react-icons/bi'
import { MdCheck } from 'react-icons/md'
import { toast } from 'react-toastify'
import { FbLink } from '../../components/FbUI/FbLink/styles'
import { formatUsdDecimal, formatInteger } from '../../utils/formatting'

// Helper function similar to createTableColumnHelper but simplified for this use case
const createAttributionCodeColumnHelper = (
  tooltips: Record<string, unknown> = {}
) => {
  return {
    retrieveColumn: (
      columnId: string,
      overrides: { meta?: Record<string, unknown> } = {}
    ) => {
      // Access the column info directly from the Record
      const columnInfo =
        ATTRIBUTION_CODES_COLUMNS[
          columnId as keyof typeof ATTRIBUTION_CODES_COLUMNS
        ]
      if (!columnInfo) {
        throw new Error(
          `Column ${columnId} not found in ATTRIBUTION_CODES_COLUMNS`
        )
      }

      return {
        id: columnId,
        header: columnInfo.header,
        meta: {
          headerGroup: columnInfo.group,
          headerTitle: columnInfo.header,
          tooltip: tooltips[columnId],
          ...(overrides.meta || {}),
        },
        ...overrides,
      }
    },
  }
}

// Component for the copy button that copies a link with attribution code
const CopyAttributionLink = ({
  code,
  offerCode,
}: {
  code: string
  offerCode: string
}) => {
  const [copied, setCopied] = useState(false)

  const copyToClipboard = () => {
    // Create URL for the offer with the attribution code
    const url = `${import.meta.env.VITE_TRADESPEND_APP_HOST || 'http://localhost:5174'}/offer/${offerCode}?ac=${code}`

    navigator.clipboard
      .writeText(url)
      .then(() => {
        setCopied(true)
        toast.success('Link copied to clipboard')

        // Reset copied state after 2 seconds
        setTimeout(() => {
          setCopied(false)
        }, 2000)
      })
      .catch((err) => {
        console.error('Failed to copy: ', err)
        toast.error('Failed to copy link')
      })
  }

  return (
    <button
      onClick={copyToClipboard}
      className="ml-2 p-1 text-gray-500 hover:text-blue-500 rounded-md hover:bg-gray-100 focus:outline-none"
      title="Copy attribution link to clipboard"
      aria-label="Copy attribution link"
    >
      {copied ? (
        <MdCheck className="text-green-500" size={16} />
      ) : (
        <BiCopy size={16} />
      )}
    </button>
  )
}

export const useAttributionCodesTableColumns = () => {
  const { preferences } = usePreferences()
  const tooltips: Record<string, unknown> = preferences?.tooltips || {}

  const columnHelper = createColumnHelper<AttributionCode>()
  const attributionCodeColumnHelper =
    createAttributionCodeColumnHelper(tooltips)

  const cols = useMemo(
    () => [
      // Code Information Group
      columnHelper.accessor('name', {
        ...attributionCodeColumnHelper.retrieveColumn('name', {
          meta: {
            isEditable: false,
            isFirstColumn: true,
          },
        }),
        size: 300,
        cell: (info) => {
          return <AttributionCodeCell attributionCodes={[info.row.original]} />
        },
      }),
      columnHelper.accessor('code', {
        ...attributionCodeColumnHelper.retrieveColumn('code'),
        size: 200,
        cell: (info) => {
          const code = info.getValue()
          const isDefault = info.row.original.is_default

          // If this is a default code, don't display any code
          if (isDefault) {
            return <span className="text-gray-400 italic">Default Code</span>
          }

          return (
            <div className="flex items-center">
              <code className="bg-gray-100 px-2 py-1 rounded font-mono text-sm">
                {code}
              </code>
              <CopyAttributionLink
                code={code}
                offerCode={info.row.original.offer_code || ''}
              />
            </div>
          )
        },
      }),
      // Add URL column
      columnHelper.accessor(
        (row) =>
          row.is_default
            ? ''
            : `${import.meta.env.VITE_TRADESPEND_APP_HOST || 'http://localhost:5174'}/offer/${row.offer_code}?ac=${row.code}`,
        {
          id: 'url',
          header: 'Shareable URL',
          meta: {
            headerGroup: 'Code Information',
            headerTitle: 'Shareable URL',
            tooltip: tooltips['url'],
          },
          size: 350,
          cell: (info) => {
            const url = info.getValue()
            const isDefault = info.row.original.is_default

            if (isDefault) {
              return <span className="text-gray-400 italic">N/A</span>
            }

            return (
              <div className="flex items-center">
                <div className="truncate max-w-xs">
                  <FbLink
                    to={url}
                    className="font-mono text-xs bg-gray-100 px-2 py-1 rounded"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {url}
                  </FbLink>
                </div>
                <button
                  onClick={() => {
                    navigator.clipboard
                      .writeText(url)
                      .then(() => toast.success('URL copied to clipboard'))
                      .catch(() => toast.error('Failed to copy URL'))
                  }}
                  className="ml-2 p-1 text-gray-500 hover:text-blue-500 rounded-md hover:bg-gray-100 focus:outline-none"
                  title="Copy URL to clipboard"
                >
                  <BiCopy size={16} />
                </button>
              </div>
            )
          },
        }
      ),
      columnHelper.accessor('description', {
        ...attributionCodeColumnHelper.retrieveColumn('description'),
        size: 250,
        cell: (info) => (
          <OverflownText maxLines={2} className="text-gray-700">
            {info.getValue() || '-'}
          </OverflownText>
        ),
      }),
      columnHelper.accessor('view_count', {
        ...attributionCodeColumnHelper.retrieveColumn('view_count'),
        size: 100,
        cell: (info) => formatInteger(info.getValue() || 0),
      }),
      columnHelper.accessor('total_enrollments', {
        ...attributionCodeColumnHelper.retrieveColumn('total_enrollments'),
        size: 120,
        cell: (info) => formatInteger(info.getValue() || 0),
      }),
      columnHelper.accessor('total_claims', {
        ...attributionCodeColumnHelper.retrieveColumn('total_claims'),
        size: 120,
        cell: (info) => formatInteger(info.getValue() || 0),
      }),
      columnHelper.accessor('pending_claims', {
        ...attributionCodeColumnHelper.retrieveColumn('pending_claims'),
        size: 140,
        cell: (info) => formatInteger(info.getValue() || 0),
      }),
      columnHelper.accessor('invalid_claims', {
        ...attributionCodeColumnHelper.retrieveColumn('invalid_claims'),
        size: 140,
        cell: (info) => formatInteger(info.getValue() || 0),
      }),
      columnHelper.accessor('paid_claims', {
        ...attributionCodeColumnHelper.retrieveColumn('paid_claims'),
        size: 120,
        cell: (info) => formatInteger(info.getValue() || 0),
      }),
      columnHelper.accessor('unpaid_valid_claims', {
        ...attributionCodeColumnHelper.retrieveColumn('unpaid_valid_claims'),
        size: 160,
        cell: (info) => formatInteger(info.getValue() || 0),
      }),
      columnHelper.accessor('total_validated_units', {
        ...attributionCodeColumnHelper.retrieveColumn('total_validated_units'),
        size: 140,
        cell: (info) => formatInteger(info.getValue() || 0),
      }),
      columnHelper.accessor('total_validated_payout', {
        ...attributionCodeColumnHelper.retrieveColumn('total_validated_payout'),
        size: 150,
        cell: (info) => formatUsdDecimal(info.getValue() || 0),
      }),
      columnHelper.accessor('created_by', {
        ...attributionCodeColumnHelper.retrieveColumn('created_by'),
        size: 150,
        cell: (info) => {
          const createdBy = info.getValue()
          return createdBy
            ? `${createdBy.first_name} ${createdBy.last_name}`
            : 'System'
        },
      }),
      columnHelper.accessor('created', {
        ...attributionCodeColumnHelper.retrieveColumn('created'),
        size: 150,
        cell: (info) => {
          return format(new Date(info.getValue()), 'MMM d, yyyy')
        },
      }),
      columnHelper.accessor('modified', {
        ...attributionCodeColumnHelper.retrieveColumn('modified'),
        size: 150,
        cell: (info) => {
          return format(new Date(info.getValue()), 'MMM d, yyyy')
        },
      }),
    ],
    [columnHelper, attributionCodeColumnHelper, tooltips]
  )

  return cols
}
