import { Text } from '../../FbUI/Text'
import { Control, FieldValues, Path, useController } from 'react-hook-form'
import TextAreaInputBase from './textarea-input-base'

export function TextareaInput<T extends FieldValues>({
  name,
  label,
  control,
  placeholder,
  maxLength,
  secondaryLabel,
  optional = false,
}: {
  control: Control<T>
  name: Path<T>
  label?: string
  placeholder?: string
  type?: string
  maxLength?: number
  optional?: boolean
  secondaryLabel?: string
}) {
  const {
    field: { ref, onChange, value },
    fieldState: { error },
  } = useController({ name: name, control })

  return (
    <div className={'flex flex-col mt-3 w-full'}>
      {label && (
        <label
          htmlFor={name}
          className="text-base font-medium text-gray-800 select-none"
        >
          {label}
          {optional && (
            <span className={'text-sm italic font-normal'}> - optional</span>
          )}
          {secondaryLabel && (
            <span className={'text-sm italic font-normal'}>
              {secondaryLabel}
            </span>
          )}
        </label>
      )}
      <TextAreaInputBase
        id={name}
        onChange={onChange}
        value={value}
        ref={ref}
        placeholder={placeholder}
        maxLength={maxLength}
      />
      <Text>{error?.message ?? ''}</Text>
    </div>
  )
}
