import { DataTypeColumn } from '../../components/DataExpansionComponents/Common/commonColumnHelpers'
import { AttributionCode } from './tradespend_api'

type CustomFields = 'url'

export const ATTRIBUTION_CODES_COLUMNS: Record<
  keyof AttributionCode | CustomFields,
  DataTypeColumn
> = {
  id: {
    header: 'ID',
    group: 'Code Information',
  },
  name: {
    header: 'Name',
    group: 'Code Information',
  },
  code: {
    header: 'Code',
    group: 'Code Information',
  },
  url: {
    header: 'Shareable URL',
    group: 'Code Information',
  },
  description: {
    header: 'Description',
    group: 'Code Information',
  },
  color: {
    header: 'Color',
    group: 'Code Information',
  },
  is_default: {
    header: 'Default',
    group: 'Code Information',
  },
  view_count: {
    header: 'Views',
    group: 'Usage Information',
  },
  total_enrollments: {
    header: 'Enrollments',
    group: 'Usage Information',
  },
  total_claims: {
    header: 'Total Claims',
    group: 'Usage Information',
  },
  pending_claims: {
    header: 'Pending Claims',
    group: 'Usage Information',
  },
  invalid_claims: {
    header: 'Invalid Claims',
    group: 'Usage Information',
  },
  paid_claims: {
    header: 'Paid Claims',
    group: 'Usage Information',
  },
  unpaid_valid_claims: {
    header: 'Unpaid Valid Claims',
    group: 'Usage Information',
  },
  total_validated_units: {
    header: 'Validated Units',
    group: 'Usage Information',
  },
  total_validated_payout: {
    header: 'Validated Payout',
    group: 'Usage Information',
  },
  created_by: {
    header: 'Created By',
    group: 'Metadata',
  },
  assigned_by: {
    header: 'Assigned By',
    group: 'Metadata',
  },
  created: {
    header: 'Created Date',
    group: 'Metadata',
  },
  modified: {
    header: 'Last Modified',
    group: 'Metadata',
  },
  offer: {
    header: 'Offer',
    group: 'Code Information',
  },
  offer_code: {
    header: 'Offer Code',
    group: 'Code Information',
  },
}
