import FilterModal, {
  FilterGroup,
} from '../../components/Filters/components/FilterModal'
import { DateRangeFilter } from '../../components/Filters/fields/DateRangeFilter'
import { RangeFilter } from '../../components/Filters/fields/RangeFilter'
import { FilterIdentifier } from '../../models/saved_view'
import { filterStoreRepo } from '../../components/Filters/FilterStore'
import { createFilterColumnHelper } from '../../components/DataExpansionComponents/Common/commonColumnHelpers'
import { ATTRIBUTION_CODES_COLUMNS } from './attributionCodesColumnInfo'
import { QuickValueFilter } from '../../components/Filters/fields/QuickFilter'

export function AttributionCodesFilterset(props: {
  filterIdentifier: FilterIdentifier
}) {
  const filterStore = filterStoreRepo.getStore(props.filterIdentifier)

  const attributionCodesFilterHelper = createFilterColumnHelper({
    columns: ATTRIBUTION_CODES_COLUMNS,
  })

  return (
    <FilterModal store={filterStore} identifier={props.filterIdentifier}>
      <FilterGroup title="Quick filters" fontStyle="italic">
        <QuickValueFilter
          {...attributionCodesFilterHelper.retrieveProps('view_count')}
          title="Views"
          valueLabel="No Views"
          predefinedValue={[{ label: 'No Views', value: 'no_views' }]}
          filterStore={filterStore}
        />
        <QuickValueFilter
          {...attributionCodesFilterHelper.retrieveProps('total_enrollments')}
          title="Enrollments"
          valueLabel="No Enrollments"
          predefinedValue={[
            { label: 'No Enrollments', value: 'no_enrollments' },
          ]}
          filterStore={filterStore}
        />
      </FilterGroup>

      <FilterGroup title="Usage Information">
        <RangeFilter
          {...attributionCodesFilterHelper.retrieveProps('view_count')}
          store={filterStore}
        />
        <RangeFilter
          {...attributionCodesFilterHelper.retrieveProps('total_enrollments')}
          store={filterStore}
        />
        <RangeFilter
          {...attributionCodesFilterHelper.retrieveProps('total_claims')}
          store={filterStore}
        />
        <RangeFilter
          {...attributionCodesFilterHelper.retrieveProps('pending_claims')}
          store={filterStore}
        />
        <RangeFilter
          {...attributionCodesFilterHelper.retrieveProps('invalid_claims')}
          store={filterStore}
        />
        <RangeFilter
          {...attributionCodesFilterHelper.retrieveProps('paid_claims')}
          store={filterStore}
        />
        <RangeFilter
          {...attributionCodesFilterHelper.retrieveProps('unpaid_valid_claims')}
          store={filterStore}
        />
        <RangeFilter
          {...attributionCodesFilterHelper.retrieveProps(
            'total_validated_units'
          )}
          store={filterStore}
        />
        <RangeFilter
          {...attributionCodesFilterHelper.retrieveProps(
            'total_validated_payout'
          )}
          store={filterStore}
        />
      </FilterGroup>

      <FilterGroup title="Dates">
        <DateRangeFilter
          {...attributionCodesFilterHelper.retrieveProps('created')}
          filterStore={filterStore}
        />
        <DateRangeFilter
          {...attributionCodesFilterHelper.retrieveProps('modified')}
          filterStore={filterStore}
        />
      </FilterGroup>
    </FilterModal>
  )
}
