import { useQuery } from '@tanstack/react-query'
import { CellContext } from '@tanstack/react-table'
import to from 'await-to-js'
import {
  DataTable,
  DataTableProvider,
  useDataTableContext,
} from 'components/DataTable'
import FbButton from 'components/FbUI/FbButton'
import {
  filterStoreRepo,
  useFilterParams,
} from 'components/Filters/FilterStore'
import { ColumnSelectorRecipient } from 'components/Modals/ColumnModal/ColumnModal'
import * as S from 'components/Tables/CommonTable.styles'
import dialogConfirm from 'components/dialogConfirm'
import { ContactResponse } from 'models/contacts'
import { Deal } from 'models/deals'
import React, { useEffect, useMemo, useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import {
  BsDownload,
  BsPencil,
  BsPerson,
  BsSignpost,
  BsTags,
  BsTruck,
  BsXCircle,
} from 'react-icons/bs'
import { toast } from 'react-toastify'
import apiService from 'services/api'
import { ColumnsStoreProvider } from 'stores/ColumnsStore/ColumnsStoreProvider'
import { default as styled } from 'styled-components'
import { IContactCompany } from '../../../models/contact_companies'
import { FilterIdentifier } from '../../../models/saved_view'

import { getTableQueryKey } from '../../../utils/getTableQueryKey'
import { ActionButtons } from '../../Buttons/ActionButtons'
import TableButtonAction from '../../Buttons/TableButtons/TableButtonAction'
import { dataTableSortingStoreRepo } from '../../DataTable/DataTableSorting/DataTableSortingStore'
import { DataTableContainer } from '../../DataTable/UI'
import { IColumnVisibility } from '../../DataTable/types'
import { EditDealModal } from '../../Deals/EditDealModal/EditDealModal'

import { TableSearch } from '../../Filters/TableSearch'
import { FilterChips } from '../../Filters/components/FilterChips/FilterChips'
import DistributorAssignModal from '../../Modals/AssignmentModal/DistributorAssignModal'
import TagAssignModal from '../../Modals/AssignmentModal/TagAssignModal'
import DealExportModal from '../../Modals/ExportModal/DealExportModal'
import DealsOverrideModal from '../../Modals/FormActionModal/DealsOverrideModal'
import AccountOwnerSelectModal from '../../Modals/SelectModal/AccountOwnerSelectModal'
import SalesStageSelectModal from '../../Modals/SelectModal/SalesStageSelectModal'
import { cn } from '../../UI/cn'
import { DealsFilterset } from './DealsFilterset'
import { useDealsTableColumns } from './useDealsTableColumns'
import { useBulkDealsDeleter } from './useBulkDealsDeleter'

const tooltip = <Tooltip id="tooltip">Download limited to 10,000 rows</Tooltip>
type SortableFields = (keyof ContactResponse | string)[]

const sortableFields: SortableFields = [
  'id',
  'company',
  'product',

  'monthly_volume_predicted',
  'monthly_volume_override',
  'monthly_revenue_predicted',
  'monthly_revenue_override',
  'annual_volume',
  'annual_revenue',
  'annual_total',
  'annual_brand_opp',

  'close_date_override',
  'sales_stage',
  'account_owner',
  'created',
  'modified',
  'last_datetime_stage_changed',
  'distributors',
  'expected_days_to_close',
  'note_count',
  'category',
  'sub_category',
  'contact_count',
  'contact_tags',
  'days_since_last_modified',
  'company_size',
  'estimated_meals_per_day',
  'days_since_sent',
  'days_since_reply',
  'days_since_deal_last_modified',
]

const PAGE_SIZE = 100

interface DealsTableProps {
  setTotalRowsCount?: (count: number) => void
  forCompany?: Partial<IContactCompany>
  showCompanyColumn?: boolean
  enablePagination?: boolean
  disableUrlPagination?: boolean
  baseFilters?: Record<string, any>
  tableKey: string
  filterIdentifierModifier?: string
}

function DealsTableComponent(props: DealsTableProps) {
  const api = apiService()

  const { sorting } = dataTableSortingStoreRepo.getStore(props.tableKey)()
  const {
    state: { totalSelectedRows },
    methods: { clearSelectedRows, setTotalRowsInBackend },
  } = useDataTableContext()

  const [dealToEdit, setDealToEdit] = useState<Deal>()
  const [showAssignTags, setShowAssignTags] = useState(false)
  const [showAssignDistributors, setShowAssignDistributors] = useState(false)
  const [showSelectAccountOwner, setShowSelectAccountOwner] = useState(false)
  const [showSelectSalesStage, setShowSelectSalesStage] = useState(false)
  const [showOverrideModal, setShowOverrideModal] = useState(false)
  const [showExportModal, setShowExportModal] = React.useState(false)
  const IDENTIFIER = ('DEALS_TABLE' +
    (props.filterIdentifierModifier ?? '')) as FilterIdentifier
  const dealsFilterStore = filterStoreRepo.getStore(IDENTIFIER)
  const { page, setPage } = dealsFilterStore()
  const filtersAsParams = {
    ...props.baseFilters,
    ...useFilterParams(dealsFilterStore),
  }

  const filterParams = useMemo(() => {
    const params: Record<string, unknown> = { ...filtersAsParams }

    if (props.forCompany) {
      params['company'] = props.forCompany.id
    }
    return params
  }, [filtersAsParams, props.forCompany])

  const sortParams = useMemo(() => {
    const params: Record<string, unknown> = {}

    if (sorting?.length) {
      params['sort'] = sorting[0].desc ? '-' + sorting[0]?.id : sorting[0]?.id
    }

    return params
  }, [sorting])

  const filterAndSortParams = useMemo(
    () => ({
      ...filterParams,
      ...sortParams,
    }),
    [filterParams, sortParams]
  )

  const TABLE_QUERY_KEY = getTableQueryKey({
    tableKey: props.tableKey,
    filterParams: filterAndSortParams,
    page: page,
  })

  // DATA FETCHING
  const { isFetching, data, refetch } = useQuery({
    queryKey: TABLE_QUERY_KEY,
    queryFn: ({ signal }) => {
      clearSelectedRows()
      return api.getDeals(
        {
          ...filterAndSortParams,
          page: page,
          limit: PAGE_SIZE,
        },
        signal
      )
    },
  })

  useEffect(() => {
    if (data?.count) {
      console.log('data.count', data.count)
      props.setTotalRowsCount?.(data.count)
      setTotalRowsInBackend(data.count)
    }
  }, [data])

  // END DATA FETCHING

  const bulkDeleteDeals = useBulkDealsDeleter({
    data: data?.results ?? [],
    accessorKey: 'id',
    tableQueryKey: TABLE_QUERY_KEY,
    filterParams: filterAndSortParams,
  })

  async function handleDealDelete(dealId: number) {
    if (
      await dialogConfirm(
        <>
          Are you sure you want to delete this <b>deal</b>? This action cannot
          be undone.
        </>,
        'Delete Deal?',
        '',
        { confirmLabel: 'Delete' }
      )
    ) {
      const [err] = await to(api.deleteDeal(dealId))
      if (!err) {
        toast.success('Deal deleted successfully')
        void refetch()
      }
    }
  }

  const columns = [
    ...useDealsTableColumns({
      showCompanyColumn: props.showCompanyColumn,
      sorting,
    }),
    {
      id: 'actions',
      header: 'Actions',
      size: 150,
      meta: {
        isEditable: false,
        headerDisableOrdering: true,
        isLastColumn: true,
        rightAlign: true,
      },
      cell: (info: CellContext<Deal, unknown>) => {
        const deal = info.row.original
        const dealId = deal.id

        return (
          <ActionButtons
            className={'justify-start'}
            onDelete={() => handleDealDelete(dealId)}
            onEdit={() => setDealToEdit(deal)}
          />
        )
      },
    },
  ]

  return (
    <DataTableContainer key={props.tableKey + '-container'}>
      <DataTable
        tableHeader={
          <S.SearchContainer>
            <div className="flex w-full gap-2 items-center">
              <TableSearch
                className={'flex-1'}
                filterStore={dealsFilterStore}
                searchPlaceholder="Search by name, company..."
              />
              <DealsFilterset
                filterIdentifier={IDENTIFIER}
                hideCampaignsFilter={props.baseFilters?.origin_campaign}
              />
              <ColumnSelectorRecipient tableKey={props.tableKey} />
            </div>

            <div className={cn('flex flex-row justify-between gap-4')}>
              <TableButtonAction
                items={[
                  {
                    label: 'Manage Tags',
                    icon: <BsTags size={20} />,
                    callback: () => setShowAssignTags(true),
                  },
                  {
                    label: 'Add Known Distributors',
                    icon: <BsTruck size={20} />,
                    callback: () => setShowAssignDistributors(true),
                  },
                  {
                    label: 'Assign Account Owner',
                    icon: <BsPerson size={20} />,
                    callback: () => setShowSelectAccountOwner(true),
                  },
                  {
                    label: 'Change Sales Stage',
                    icon: <BsSignpost size={20} />,
                    callback: () => setShowSelectSalesStage(true),
                  },
                  {
                    label: 'Manage Override',
                    icon: <BsPencil size={20} />,
                    callback: () => setShowOverrideModal(true),
                  },
                  {
                    label: 'Delete Deals',
                    icon: <BsXCircle size={20} />,
                    callback: () => bulkDeleteDeals(),
                  },
                ]}
                selectedRowsCount={totalSelectedRows}
              />
              <FilterChips
                identifier={IDENTIFIER}
                store={dealsFilterStore}
                showActive
                clearAllButton
              />
            </div>
          </S.SearchContainer>
        }
        key={props.tableKey}
        tableKey={props.tableKey}
        loading={isFetching}
        data={data?.results ?? []}
        columns={columns}
        sortableFields={sortableFields}
        virtualizeRows={false}
        enableRowSelection
        isPaginationEnabled={props.enablePagination}
        defaultSort={[{ id: 'annual_revenue', desc: true }]}
        paginationOptions={{
          totalRows: data?.count ?? 0,
          pageSize: PAGE_SIZE,
          setPage: setPage,
          page: page,
          isPaginationLoading: isFetching,
        }}
        stickyLastColumn
        expandColAccessor={'expanded-row'}
        selectAllText="Select Deals"
        footerControls={
          <OverlayTrigger placement="top" overlay={tooltip}>
            <ExportButton
              variant="secondary"
              onClick={() => setShowExportModal(true)}
            >
              <BsDownload size={18} />
            </ExportButton>
          </OverlayTrigger>
        }
      />

      <EditDealModal
        onClose={() => setDealToEdit(undefined)}
        onDealEdited={() => refetch()}
        show={!!dealToEdit}
        deal={dealToEdit}
      />

      <DealExportModal
        count={data?.count ?? 0}
        show={showExportModal}
        _handleClose={() => setShowExportModal(false)}
        filters={filterAndSortParams}
      />

      <TagAssignModal
        show={showAssignTags}
        handleClose={() => setShowAssignTags(false)}
        tableQueryKey={TABLE_QUERY_KEY}
        data={data?.results ?? []}
        tableObjAccessor="contact_company.taglist"
        filterAndSortParams={filterAndSortParams}
        apiTagsAction={(values) => {
          return api.commonManageTags({
            values,
            companyType: 'deals-table',
          })
        }}
        idAccessor="contact_company.id"
      />

      <DistributorAssignModal
        show={showAssignDistributors}
        handleClose={() => setShowAssignDistributors(false)}
        tableQueryKey={TABLE_QUERY_KEY}
        data={data?.results ?? []}
        idAccessor="id"
        tableObjAccessor="contact_company.distributors"
        filterAndSortParams={filterAndSortParams}
        apiDistributorsAction={(values) =>
          api.commonManageDistributors({
            type: 'deals-table',
            values,
          })
        }
      />

      <AccountOwnerSelectModal
        show={showSelectAccountOwner}
        handleClose={() => setShowSelectAccountOwner(false)}
        tableQueryKey={TABLE_QUERY_KEY}
        data={data?.results ?? []}
        idAccessor="id"
        filterAndSortParams={filterAndSortParams}
      />

      <SalesStageSelectModal
        show={showSelectSalesStage}
        handleClose={() => setShowSelectSalesStage(false)}
        tableQueryKey={TABLE_QUERY_KEY}
        data={data?.results ?? []}
        idAccessor="id"
        filterAndSortParams={filterAndSortParams}
      />

      <DealsOverrideModal
        show={showOverrideModal}
        handleClose={() => setShowOverrideModal(false)}
        tableQueryKey={TABLE_QUERY_KEY}
        data={data?.results ?? []}
        idAccessor="id"
        filterAndSortParams={filterAndSortParams}
      />
    </DataTableContainer>
  )
}

export function DealsTable(
  props: DealsTableProps & {
    defaultColumnVisibility?: IColumnVisibility<unknown>
  }
) {
  const { tableKey, defaultColumnVisibility, ...rest } = props
  return (
    <ColumnsStoreProvider
      tableKey={tableKey}
      savedPresetsIdentifier={'DEALS_TABLE'}
      defaultColumnVisibility={defaultColumnVisibility}
      preferredGroupOrder={[
        'Company Attributes',
        'Campaigns and Deals',
        'Forecast',
      ]}
    >
      <DataTableProvider tableKey={tableKey}>
        <DealsTableComponent tableKey={tableKey} {...rest} />
      </DataTableProvider>
    </ColumnsStoreProvider>
  )
}

const ExportButton = styled(FbButton)`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 14px;

  gap: 8px;

  height: 44px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`
