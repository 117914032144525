import { Modal } from '../../../components/UI/Modal/Modal'
import { useState, useRef } from 'react'
import { toast } from 'react-toastify'
import { AttributionCode } from '../tradespend_api'
import { BiCopy, BiChevronDown, BiDownload } from 'react-icons/bi'
import { MdCheck, MdQrCode } from 'react-icons/md'
import FbButton from '../../../components/FbUI/FbButton'
import QRCode from 'react-qr-code'

interface AttributionCodeDetailsModalProps {
  open: boolean
  onClose: () => void
  code: AttributionCode | null
}

function AttributionCodeDetailsModal({
  open,
  onClose,
  code,
}: AttributionCodeDetailsModalProps) {
  const [copied, setCopied] = useState(false)
  const [qrSectionExpanded, setQrSectionExpanded] = useState(false)
  const qrRef = useRef<HTMLDivElement>(null)

  // Generate shareable URL with the attribution code
  const shareableUrl =
    code && !code.is_default
      ? `${import.meta.env.VITE_TRADESPEND_APP_HOST || 'http://localhost:5174'}/offer/${code.offer_code}?ac=${code.code}`
      : ''

  // Copy to clipboard function
  const copyToClipboard = () => {
    if (!shareableUrl) return

    navigator.clipboard
      .writeText(shareableUrl)
      .then(() => {
        setCopied(true)
        toast.success('Link copied to clipboard')

        // Reset copied state after 2 seconds
        setTimeout(() => {
          setCopied(false)
        }, 2000)
      })
      .catch((err) => {
        console.error('Failed to copy: ', err)
        toast.error('Failed to copy link')
      })
  }

  // Function to download QR code as PNG
  const downloadQRCode = () => {
    if (!qrRef.current || !code) return

    // Get the SVG element
    const svg = qrRef.current.querySelector('svg')
    if (!svg) return

    // Create a canvas element
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    if (!ctx) return

    // Set canvas dimensions
    const scaleFactor = 4 // Make the downloaded image larger and clearer
    canvas.width = svg.clientWidth * scaleFactor
    canvas.height = svg.clientHeight * scaleFactor

    // Create an image from the SVG
    const svgData = new XMLSerializer().serializeToString(svg)
    const img = new Image()

    img.onload = () => {
      // Fill with white background
      ctx.fillStyle = 'white'
      ctx.fillRect(0, 0, canvas.width, canvas.height)

      // Draw the image
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height)

      // Create download link
      const downloadLink = document.createElement('a')
      downloadLink.download = `${code.name.replace(/\s+/g, '-').toLowerCase()}-qrcode.png`
      downloadLink.href = canvas.toDataURL('image/png')
      downloadLink.click()

      toast.success('QR Code downloaded successfully!')
    }

    img.src =
      'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgData)))
  }

  if (!code) return null

  return (
    <Modal
      open={open}
      onOpenChange={onClose}
      title="Attribution Code Details"
      size="lg"
      acceptButtonText="Close"
      onAccept={onClose}
    >
      <div className="p-4 space-y-4">
        {/* Code name and color */}
        <div className="flex items-center space-x-2">
          <div
            className="w-4 h-4 rounded-full"
            style={{ backgroundColor: code.color || '#ccc' }}
          />
          <h3 className="text-lg font-medium">{code.name}</h3>
          {code.is_default && (
            <span className="bg-blue-100 text-blue-800 text-xs px-2 py-1 rounded">
              Default
            </span>
          )}
        </div>

        {/* Attribution Code */}
        {!code.is_default && (
          <div className="mt-4">
            <h4 className="text-sm font-medium text-gray-700 mb-2">
              Attribution Code
            </h4>
            <div className="flex items-center">
              <code className="bg-gray-100 px-3 py-2 rounded font-mono text-sm flex-1">
                {code.code}
              </code>
            </div>
          </div>
        )}

        {/* Shareable Link */}
        {!code.is_default && (
          <div className="mt-4">
            <h4 className="text-sm font-medium text-gray-700 mb-2">
              Shareable Link
            </h4>
            <div className="flex items-center">
              <input
                type="text"
                readOnly
                value={shareableUrl}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              />
              <FbButton
                onClick={copyToClipboard}
                className="ml-2 px-3"
                title="Copy shareable link"
              >
                {copied ? <MdCheck size={18} /> : <BiCopy size={18} />}
              </FbButton>
            </div>
            <p className="text-xs text-gray-500 mt-1">
              Share this link with users to track this attribution source
            </p>
          </div>
        )}

        {/* QR Code Accordion Section */}
        {!code.is_default && shareableUrl && (
          <div className="mt-6 border rounded-lg overflow-hidden">
            <div
              className="flex items-center justify-between p-3 bg-gray-50 cursor-pointer"
              onClick={() => setQrSectionExpanded(!qrSectionExpanded)}
            >
              <div className="flex items-center">
                <MdQrCode size={20} className="mr-2 text-gray-600" />
                <h4 className="text-sm font-medium text-gray-700">QR Code</h4>
              </div>
              <BiChevronDown
                size={20}
                className={`text-gray-600 transition-transform duration-300 ${
                  qrSectionExpanded ? 'rotate-180' : ''
                }`}
              />
            </div>

            {qrSectionExpanded && (
              <div className="p-4 border-t">
                <p className="text-gray-600 mb-4 text-sm">
                  Use this QR code in your marketing materials to let customers
                  easily access this offer by scanning with their phone.
                </p>
                <div
                  ref={qrRef}
                  className="bg-white p-6 rounded-lg flex flex-col items-center justify-center border"
                >
                  <div className="mb-4 text-center">
                    <div className="text-sm text-gray-500 mb-1">
                      Scan to view
                    </div>
                    <div className="font-medium text-gray-900">{code.name}</div>
                  </div>
                  <QRCode
                    value={shareableUrl}
                    size={200}
                    style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                  />
                  <div className="mt-4 text-xs text-gray-400 text-center break-all">
                    {shareableUrl}
                  </div>
                </div>
                <div className="mt-4 flex justify-end">
                  <FbButton
                    onClick={downloadQRCode}
                    className="flex items-center gap-2"
                  >
                    <BiDownload size={18} />
                    <span>Download QR Code</span>
                  </FbButton>
                </div>
              </div>
            )}
          </div>
        )}

        {/* Description */}
        {code.description && (
          <div className="mt-4">
            <h4 className="text-sm font-medium text-gray-700 mb-2">
              Description
            </h4>
            <p className="text-sm text-gray-600">{code.description}</p>
          </div>
        )}

        {/* Usage statistics */}
        <div className="mt-4">
          <h4 className="text-sm font-medium text-gray-700 mb-2">
            Usage Statistics
          </h4>
          <div className="bg-gray-50 p-3 rounded-lg">
            <p className="text-sm">
              <span className="font-medium">Views:</span> {code.view_count || 0}
            </p>
          </div>
        </div>

        {/* Created by */}
        <div className="mt-4">
          <h4 className="text-sm font-medium text-gray-700 mb-2">Created By</h4>
          <p className="text-sm">
            {code.created_by
              ? `${code.created_by.first_name} ${code.created_by.last_name}`
              : 'System'}
          </p>
          <p className="text-xs text-gray-500">
            {code.created
              ? new Date(code.created).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })
              : ''}
          </p>
        </div>
      </div>
    </Modal>
  )
}

export default AttributionCodeDetailsModal
