import './Editor.css'
import { useEffect, useMemo } from 'react'
import 'quill/dist/quill.snow.css'
import 'quill-mention/dist/quill.mention.css'
import Quill from 'quill'
import { MentionBlot, Mention } from 'quill-mention'
import apiService from 'services/api'
import { useAuth } from 'context/authentication/useAuth'
Quill.register({ 'blots/mention': MentionBlot, 'modules/mention': Mention })

interface EditorProps {
  value: string
  onChange?: (value: string | undefined) => void
}

export interface CommonEditorProps {
  theme?: 'snow' | 'bubble'
  placeholder?: string
  label?: string
  optional?: boolean
  additionalToolbarButtons?: React.ReactNode[]
  readOnly?: boolean
  disableToolbar?: boolean
  enableMentions?: boolean
  className?: string
  borderless?: boolean
}

export function Editor(props: EditorProps & CommonEditorProps) {
  const {
    onChange,
    theme,
    placeholder,
    label,
    optional,
    readOnly,
    disableToolbar,
    value,
    className,
    borderless,
  } = props

  const api = apiService()
  const { data: users } = api.useGetUserOptions({
    onlyActive: true,
    withNoneOption: false,
  })
  const { user } = useAuth()

  // We need to generate a unique ID for the toolbar container since multiple can coexist on the same page
  const uniqueId = useMemo(() => Math.random().toString(36).substring(7), [])

  const onChangeHandler = (value: string) => {
    let newValue: string = value
    if (newValue === '<p><br></p>') {
      // empty string on the WYSIWYG editor
      newValue = ''
    }
    onChange?.(newValue)
  }

  useEffect(() => {
    if (!users) return

    const userOptions = users
      ?.filter((u) => u.value !== user?.id)
      .map((u) => ({
        id: u.value,
        value: u.label,
      }))

    const modules: Record<string, unknown> = {
      toolbar: disableToolbar
        ? false
        : {
            container: `#toolbar-${uniqueId}`,
          },
    }

    if (props.enableMentions) {
      modules.mention = {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ['@'],
        source: function (searchTerm: string, renderList: any) {
          const MAX_OPTIONS = 5
          const values = userOptions ?? []
          console.log(values)

          if (searchTerm.length === 0) {
            renderList(values.slice(0, MAX_OPTIONS), searchTerm)
          } else {
            const matches = []
            for (let i = 0; i < values.length; i++) {
              if (
                values[i].value.toLowerCase().includes(searchTerm.toLowerCase())
              ) {
                matches.push(values[i])
              }
            }
            renderList(matches.slice(0, MAX_OPTIONS), searchTerm)
          }
        },
      }
    }

    const editor = new Quill(`#editor-${uniqueId}`, {
      readOnly: readOnly,
      theme: theme ?? 'snow',
      placeholder: placeholder || 'Add a new note here',
      modules,
    })

    editor.on('text-change', () => onChangeHandler(editor.getSemanticHTML()))

    if (value) {
      editor.setContents(
        editor.clipboard.convert({
          html: value,
        })
      )
    }
  }, [users])

  return (
    <div className="mt-2">
      {label && (
        <label className="text-base font-medium text-gray-800 select-none mb-1">
          {label}
          {optional && (
            <span className={'text-sm italic font-normal'}> - optional</span>
          )}{' '}
        </label>
      )}
      <div className={borderless ? 'borderless-editor' : ''}>
        <div className={className} id={`editor-${uniqueId}`}></div>
        <div style={{ display: props.disableToolbar ? 'none' : 'block' }}>
          <div id={'toolbar-' + uniqueId}>
            <button className="ql-bold"></button>
            <button className="ql-italic"></button>
            <button className="ql-underline"></button>
            <button className="ql-strike"></button>
            <button className="ql-list" value="bullet"></button>
            <button className="ql-list" value="ordered"></button>
            {/* Custom attachment button */}
            {props.additionalToolbarButtons && props.additionalToolbarButtons}
          </div>
        </div>
      </div>
    </div>
  )
}
