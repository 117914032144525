import { Anchor } from 'components/UI/Anchor'
import { formatPhoneNumber } from 'utils/formatting'

export const PhoneCell = ({
  phoneNumber,
  extension,
}: {
  phoneNumber?: string
  extension?: string
}) => {
  if (!phoneNumber) return '-'

  const formatted = formatPhoneNumber({ phoneNumber, extension })
  return <Anchor href={`tel:${formatted}`}>{formatted}</Anchor>
}
