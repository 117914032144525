import { IContactCompany } from 'models/contact_companies'
import { DataTypeColumn } from 'components/DataExpansionComponents/Common/commonColumnHelpers'

type CustomFields = 'type'

export const COMPANIES_COLUMNS = {
  name: {
    header: 'Company',
    group: 'Opportunity Attributes',
  },
  created: {
    header: 'Date Created',
    group: 'Opportunity Attributes',
  },
  google_place_url: {
    header: 'Place URL',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_google_place_url',
  },
  city: {
    header: 'City',
    group: 'Opportunity Attributes',
  },
  state: {
    header: 'State',
    group: 'Opportunity Attributes',
  },
  country: {
    header: 'Country',
    group: 'Opportunity Attributes',
  },
  domain: {
    header: 'Domain',
    group: 'Opportunity Attributes',
  },
  address: {
    header: 'Address',
    group: 'Opportunity Attributes',
  },
  zipcode: {
    header: 'ZIP Code',
    group: 'Opportunity Attributes',
  },
  category: {
    header: 'Category',
    group: 'Opportunity Attributes',
  },
  type: {
    header: 'Type',
    group: 'Opportunity Attributes',
  },
  phone_number: {
    header: 'Phone Number',
    group: 'Opportunity Attributes',
  },
  extension: {
    header: 'Extension',
    group: 'Opportunity Attributes',
  },
} satisfies Partial<
  Record<CustomFields | keyof IContactCompany, DataTypeColumn>
>
