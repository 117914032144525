import { DataTypeColumn } from '../../Common/commonColumnHelpers'
import { GooglePlaceChainBase } from 'models/google_place_chain'

const GOOGLE_PLACE_BASE_COLUMNS = {
  name: {
    header: '{datatypeName} Name',
    group: 'Opportunity Attributes',
  },
  city_summary: {
    header: 'City Summary',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_city',
  },
  state_summary: {
    header: 'State Summary',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_state',
  },
  zipcode_summary: {
    header: 'ZIP Codes',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_zipcode',
  },
  domain: {
    header: 'Domain',
    group: 'Opportunity Attributes',
    tooltipName: 'domain',
  },
  reviews_cnt: {
    header: 'Total Reviews',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_reviews_count',
  },
  reviews_avg: {
    header: 'Average Rating',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_reviews_avg',
  },
  reviews_per_score: {
    header: 'Reviews Per Score',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_reviews_per_score',
  },
  open_hours: {
    header: 'Open Hours',
    group: 'Opportunity Attributes',
    tooltipName: 'open_hours',
  },
  doors_cnt: {
    header: 'Chain Size',
    group: 'Opportunity Attributes',
    tooltipName: 'doors_cnt',
  },
} satisfies Partial<Record<keyof GooglePlaceChainBase, DataTypeColumn>>

export const getGooglePlaceBaseColumns = (datatypeName: string) => {
  return Object.entries(GOOGLE_PLACE_BASE_COLUMNS).reduce(
    (acc, [key, value]) => {
      acc[key as keyof GooglePlaceChainBase] = {
        ...value,
        header: value.header.replace('{datatypeName}', datatypeName),
      }
      return acc
    },
    {} as Record<keyof GooglePlaceChainBase, DataTypeColumn>
  )
}
