import { useMemo } from 'react'
import * as S from './NotificationItem.styles'
import Markdown from 'react-markdown'
import moment from 'moment'
import apiService from 'services/api'
import { useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { User } from 'models/user'
import notificationAvatar from 'assets/notification-avatar.png'
import { Notification } from 'models/notifications'
import DOMPurify from 'dompurify'

export type NotificationItemProps = {
  isUnread?: boolean
  notification: Notification
  onClick?: () => void
}

const NotificationAvatar = ({ fromUser }: { fromUser?: User }) => {
  if (fromUser) {
    const userInitials = useMemo(() => {
      const { first_name: firstName, last_name: lastName } = fromUser
      return `${firstName[0]}${lastName[0]}`.toLocaleUpperCase()
    }, [fromUser])

    return <S.NotificationAvatar>{userInitials}</S.NotificationAvatar>
  }
  return (
    <S.NotificationAvatar>
      <img src={notificationAvatar} alt="notification-avatar" />
    </S.NotificationAvatar>
  )
}

export const NotificationItem = ({
  isUnread = false,
  notification,
  onClick,
}: NotificationItemProps) => {
  const userName = useMemo(() => {
    if (notification.from_user) {
      const { first_name: firstName, last_name: lastName } =
        notification.from_user
      return `${firstName} ${lastName}`
    }
    return ''
  }, [notification])

  const api = apiService()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const handleClick = () => {
    if (isUnread) {
      api.markNotificationAsRead([notification.id]).then(() => {
        queryClient.invalidateQueries({ queryKey: ['notifications'] })
        queryClient.invalidateQueries({ queryKey: ['unread-notifications'] })
      })
    }
    if (notification.notification_link) {
      navigate(notification.notification_link)
    }
    if (onClick) {
      onClick()
    }
  }

  const notificationMessage = useMemo(() => {
    if (notification.event === 'NOTE_MENTIONED') {
      const parts = notification.message.split('|||')
      const sanitizedMessage = DOMPurify.sanitize(parts[1])
      return (
        <S.NotificationText>
          <span>{parts[0]}</span>
          <div className="mt-2 py-2 px-2 bg-white rounded-md border border-gray-200">
            <div dangerouslySetInnerHTML={{ __html: sanitizedMessage }} />
          </div>
        </S.NotificationText>
      )
    }

    return (
      <S.NotificationText>
        {/* Dont add the p around markdown */}
        <Markdown
          components={{
            p: ({ children }) => <>{children}</>,
          }}
        >
          {`${userName} ${notification.message}`}
        </Markdown>
      </S.NotificationText>
    )
  }, [notification])

  return (
    <S.NotificationItem
      isUnread={isUnread}
      onClick={handleClick}
      hasLink={!!notification.notification_link}
    >
      <S.NotificationLeft>
        {isUnread && <S.UnreadDot />}
        <NotificationAvatar fromUser={notification.from_user} />
      </S.NotificationLeft>
      <S.NotificationRight>
        {notificationMessage}
        <S.NotificationTime>
          {moment(notification.created).fromNow()}
        </S.NotificationTime>
      </S.NotificationRight>
    </S.NotificationItem>
  )
}
