import { ColumnDef, createColumnHelper } from '@tanstack/react-table'

import { FbLink } from 'components/FbUI/FbLink'

import { usePreferences } from 'context/preferences/PreferencesContext'
import dayjs from 'dayjs'
import React, { useMemo } from 'react'

import { getCompanyUrl } from 'utils/getCompanyUrl'
import { IContactCompany } from '../../../models/contact_companies'

import { LinkCell } from '../CommonTableCells/LinkCell'
import { featureFlagService } from '../../../utils/featureFlagService'
import { DataTableColumnSort } from '../../DataTable/DataTableSorting/DataTableSortingStore'
import { getCommonContactCompanyColumns } from 'components/DataExpansionComponents/Common/CommonTable/CommonColumns'
import { createTableColumnHelper } from 'components/DataExpansionComponents/Common/commonColumnHelpers'
import { COMPANIES_COLUMNS } from './companiesColumnInfo'
import { PhoneCell } from '../CommonTableCells/PhoneCell'

export const useCompaniesTableCols = ({
  sorting,
  inCampaigns,
}: {
  sorting?: DataTableColumnSort[]
  inCampaigns?: boolean
}) => {
  const featureFlag = featureFlagService()
  const { preferences } = usePreferences()
  const tooltips: Record<string, unknown> = preferences?.tooltips || {}
  const columnHelper = createColumnHelper<IContactCompany>()
  const companyColumnHelper = createTableColumnHelper({
    columns: COMPANIES_COLUMNS,
    tooltips,
  })

  let columns: ColumnDef<IContactCompany, any>[] = useMemo(
    () => [
      columnHelper.accessor('name', {
        ...companyColumnHelper.retrieveColumn('name', {
          meta: {
            isEditable: false,
            isFirstColumn: true,
          },
        }),
        size: 250,
        cell: (info) => (
          <FbLink to={getCompanyUrl(info.row.original)} target="_blank">
            {info.row.original.name}
          </FbLink>
        ),
      }),
      columnHelper.accessor('created', {
        ...companyColumnHelper.retrieveColumn('created'),
        size: 150,
        cell: (info) => dayjs(info.getValue()).format('MM/DD/YYYY'),
      }),
      columnHelper.accessor('google_place_url', {
        ...companyColumnHelper.retrieveColumn('google_place_url'),
        size: 150,
        cell: (info) => (info.getValue() ? <LinkCell info={info} /> : '-'),
      }),
      columnHelper.accessor('city', {
        ...companyColumnHelper.retrieveColumn('city'),
      }),
      columnHelper.accessor('state', {
        ...companyColumnHelper.retrieveColumn('state'),
        cell: (info) => {
          return info.getValue()
        },
      }),
      columnHelper.accessor('phone_number', {
        ...companyColumnHelper.retrieveColumn('phone_number'),
        cell: (info) => {
          const phone = info.getValue()
          const extension = info.row.original.extension
          return <PhoneCell phoneNumber={phone} extension={extension} />
        },
      }),
      columnHelper.accessor('country', {
        ...companyColumnHelper.retrieveColumn('country'),
      }),
      ...getCommonContactCompanyColumns(
        columnHelper,
        sorting,
        preferences,
        inCampaigns
      ),
    ],
    [tooltips, sorting, preferences, inCampaigns]
  )

  if (!featureFlag.enableCampaigns) {
    columns = columns.filter((col) => !col.id?.includes('campaigns'))
  }

  return columns
}
