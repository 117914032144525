import axios from 'axios'
import ChainHeaderCard from 'components/Cards/ChainHeaderCard'
import { CompanyAttributesManager } from 'components/CompanyAttrsManager/CompanyAttrsManager'
import NotFoundCard from 'components/NotFoundCard/NotFoundCard'
import { DoorsTable } from 'components/Tables/DoorsTable/DoorsTable'
import useDocumentTitle from 'components/useDocumentTitle'
import { usePreferences } from 'context/preferences/PreferencesContext'
import { ContactCompanyType } from 'models/contact_companies'
import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import apiService from 'services/api'
import { getIdWithPrefix } from 'utils/pathUtils'
import * as S from '../../components/Cards/Cards.styles'
import { ChainIGSection } from '../../components/Cards/ChainIGSection'
import { ChainMenuSection } from '../../components/Cards/ChainMenuSection'
import ContactsTableSection from '../../components/Cards/ContactsTableSection'
import { LTVChartsCard } from '../../components/Charts/LTVChartsCard/LTVChartsCard'
import { RatingsCard } from '../../components/Charts/RatingsCard/RatingsCard'
import DealsSection from '../../components/Deals/DealsSection/DealsSection'
import { FBPage } from '../../components/FbUI/Page/FBPage'
import NotesSection from '../../components/Notes/NotesSection/NotesSection'
import { Block } from '../../components/UI/Block'
import { CollapsibleSection } from '../../components/UI/CollapsibleSection/CollapsibleSection'
import { formatInteger, formatUsd } from '../../utils/formatting'
import { FBPageTableContainer } from '../../components/FbUI/Page/styles'
import {
  Section,
  SectionItem,
} from 'components/DataExpansionComponents/Common/CommonPage/styles'
import EmailsTableSection from 'components/Cards/EmailsTableSection'
import { featureFlagService } from 'utils/featureFlagService'

export function ChainPage() {
  const urlParams = useParams()
  const { preferences } = usePreferences()
  const chainID = urlParams?.chain_name
    ? getIdWithPrefix(
        urlParams?.chain_name,
        preferences?.company_type_prefix_map[ContactCompanyType.Chain]
      )
    : undefined

  return <ChainPageComponent chainID={chainID} />
}

export function ChainPageComponent({ chainID }: { chainID?: number }) {
  const api = apiService()
  const { isLoading: isLoadingPreferences } = usePreferences()
  const { enableEmailIntegration } = featureFlagService()
  const {
    data: chainData,
    isLoading,
    isError,
    error,
  } = api.useGetChain(chainID)

  useDocumentTitle(`${chainData?.chain || 'Not Found'} (Chain) - First Bite`)

  const [notFound, setNotFound] = useState(false)

  // Check if the error is specifically a 404 not found error
  React.useEffect(() => {
    if (
      (!isLoadingPreferences && !chainID) ||
      (isError && axios.isAxiosError(error) && error?.response?.status === 404)
    ) {
      setNotFound(true)
    }
  }, [error, isError, chainID, isLoadingPreferences])

  if (notFound) {
    return <NotFoundCard />
  }

  if (isLoading) {
    return (
      <div className={'flex items-center justify-center h-96'}>
        <Spinner animation="border" />
      </div>
    )
  }

  return (
    <FBPage className="gap-y-4" noContainer>
      {chainData ? (
        <>
          <ChainHeaderCard
            tableTypeSlug="restaurants-bars"
            contactCompanyId={chainData?.contact_company_id}
            isLoading={isLoading}
            category={'Commercial'}
            subCategory={'Restaurant Chain'}
            name={chainData?.chain}
            breadcrumbs={[{ title: 'Chain', breadcrumb: chainData?.chain }]}
            companyLogoUrl={chainData?.logo}
            description={chainData?.description}
            domainField={chainData?.domain}
            rightColumnHeader={
              <CompanyAttributesManager
                chainID={chainData?.id}
                contactCompanyID={chainData?.contact_company_id}
              />
            }
            leftFirstBiteCol={{
              chain: chainData?.count,
              category: chainData?.expense_category,
              cuisine: chainData?.cuisine_50,
              reviews: chainData?.reviews_count,
              rating: chainData?.rating,
            }}
          />

          <Block className="mb-3">
            <Section>
              <SectionItem>
                <LTVChartsCard
                  ltv={{
                    brand_ltv: chainData?.brand_ltv_sum,
                    revenue_ltv: chainData?.revenue_ltv_sum,
                    total_ltv: chainData?.total_ltv_sum,
                  }}
                />
              </SectionItem>

              <SectionItem>
                <RatingsCard
                  restaurant={{
                    avg_rating: chainData?.rating,
                    total_reviews_count: chainData?.reviews_count,
                    reviews_per_score: chainData?.reviews_per_score,
                  }}
                />
              </SectionItem>

              <SectionItem>
                <div>
                  <S.Label>Median ZIP Household Income</S.Label>
                  <S.ValueBig>{formatUsd(chainData?.median_hhi)}</S.ValueBig>
                  <S.Label>
                    Average ZIP Code Population Density (pop/sq mile)
                  </S.Label>
                  <S.ValueBig>
                    {formatInteger(chainData?.pop_density)}
                  </S.ValueBig>
                </div>
              </SectionItem>
            </Section>
          </Block>
        </>
      ) : (
        <div className={'flex items-center justify-center h-96'}>
          <Spinner animation="border" />
        </div>
      )}

      {chainData && (
        <NotesSection
          chainId={chainData.id}
          contactCompanyId={chainData.contact_company_id}
          pagination={false}
        />
      )}

      {chainData && (
        <DealsSection
          forCompany={{ id: chainData.contact_company_id }}
          companyTypeSlug="restaurants-bars"
          tableTypeSlug="restaurants-bars"
          pagination={false}
        />
      )}

      {chainData && (
        <ContactsTableSection
          data={{
            name: chainData.chain,
            id: chainData.contact_company_id,
            domain: chainData.domain,
          }}
        />
      )}

      {chainData && enableEmailIntegration && (
        <EmailsTableSection forChainProxyId={chainData.contact_company_id!} />
      )}

      <ChainIGSection instagram={chainData?.instagram} />

      <ChainMenuSection chainId={chainID} />

      <CollapsibleSection
        summary={
          <div>
            Doors{' '}
            <span className={'font-thin'}>
              ({formatInteger(chainData?.count)})
            </span>
          </div>
        }
      >
        <FBPageTableContainer
          style={{
            height: '600px',
          }}
        >
          <DoorsTable
            tableKeyForColumnOrdering={'chain-doors-table'}
            tableKey={`chain-table-${chainData?.id}`}
            chainFilter={chainID}
            isChainTable={true}
            hideTitle={true}
            disableUrlPagination
          />
        </FBPageTableContainer>
      </CollapsibleSection>
    </FBPage>
  )
}
