import * as S from './styles'
import { Control, FieldValues, Path, useController } from 'react-hook-form'
import CustomCheckbox from '../../Checkbox/checkbox'
import { ErrorLabel } from '../ErrorLabel'

interface CommonCheckboxProps {
  label?: string
  disabled?: boolean
  style?: React.CSSProperties
  wrapperStyle?: React.CSSProperties
  children?: React.ReactNode
  required?: boolean
}

interface BaseCheckboxProps extends CommonCheckboxProps {
  name: string
  value?: boolean
  onChange?: (value: boolean) => void
  error?: string
}

export interface CheckboxProps<T extends FieldValues>
  extends CommonCheckboxProps {
  control: Control<T>
  name: Path<T>
}

export function BaseCheckbox(props: BaseCheckboxProps) {
  return (
    <S.Wrapper style={props.wrapperStyle}>
      <div style={{ ...props.style, alignItems: 'center', display: 'flex' }}>
        <CustomCheckbox
          id={props.name}
          onChange={
            props.onChange
              ? (e) => props.onChange?.(e.target.checked)
              : undefined
          }
          disabled={props.disabled}
          checked={props.value}
          required={props.required}
          name={props.name}
        />
        <label htmlFor={props.name} style={{ marginLeft: 8 }}>
          {props.children ?? props.label}
        </label>
      </div>
      {props.error && <ErrorLabel message={props.error} />}
    </S.Wrapper>
  )
}

export function Checkbox<T extends FieldValues>(props: CheckboxProps<T>) {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ name: props.name, control: props.control })

  return (
    <BaseCheckbox
      {...props}
      value={value}
      onChange={onChange}
      error={error?.message}
    />
  )
}
