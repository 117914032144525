import { baseAPICall } from '../../services/baseAPICall'
import { clientInstance } from '../../utils/http-client'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { RebateOfferTerms } from '../../models/rebateofferterms'
import { PaginatedResponse } from 'models/paginated-response'

export type RebateOfferState =
  | 'PUBLISHED'
  | 'PENDING'
  | 'EXPIRED'
  | 'PAUSED'
  | 'DELETED'

export interface RebateOffer {
  id: number
  name: string
  description: string | null
  learn_more_url: string | null
  code: string
  state: RebateOfferState
  scope: string
  offer_type: string
  valid_from: string
  valid_to: string
  deleted_at: string | null
  products: Array<number>
  terms: {
    company_name: string
    unit: 'unit' | 'dollars'
    tiers: Array<{
      min: number
      max: number | null
      discount: number
      discount_type: string
    }>
    products: Array<{
      product_id: number
      product_name: string
      product_desc: string
      product_variants: Array<{
        variant_id: number
        variant_format: string
        variant_name: string
        variant_desc: string
      }>
    }>
  }
  product_names: string
  total_enrollments: number
  total_claims: number
  pending_validation_claims: number
  invalid_claims: number
  unpaid_valid_claims: number
  paid_claims: number
  total_validated_units: number | null
  total_validated_payout: number | null
  include_deleted: boolean
  attribution_codes?: Array<{
    id: number
    code: string
    name: string
    views: number
    enrollments: number
    visits: number
    is_default?: boolean
  }>
}

export type RebateEnrollment = {
  id: number
  tradespend_user_name: string
  company_name: string
  terms: any
  created: string
}

export type RebateClaimStatus =
  | 'CLAIM_SUBMITTED'
  | 'CLAIM_VALIDATED'
  | 'PENDING_MANUFACTURER_PAYMENT'
  | 'OPERATOR_PAYMENT_SUBMITTED'
  | 'PAID'
  | 'CLAIM_REJECTED'

export type RebateClaim = {
  id: number
  status: RebateClaimStatus
  company_name: string
  company_domain: string | null
  firstbite_id: string | null
  enrollment_date: string
  created: string
  offer_id: number
  offer_name: string
  products: Array<{
    id: number
    name: string
  }>
  rebate_enrollment_id: number
  payout_amount: number | null
  manufacturer_invoice_id: number | null
  notes: string | null
}

export type RebateClaimsResponse = PaginatedResponse<RebateClaim>

export type PaginatedRebateOffers = {
  count: number
  next: string | null
  previous: string | null
  results: RebateOffer[]
}

export type PaginatedRebateEnrollments = {
  count: number
  next: string | null
  previous: string | null
  results: RebateEnrollment[]
}

export type PaginatedRebateClaims = {
  count: number
  next: string | null
  previous: string | null
  results: RebateClaim[]
}

interface GetRebateOffersOptions {
  campaignId?: number
  signal?: AbortSignal
  page?: number
  limit?: number
  [key: string]: any
}

export enum RebateOfferType {
  FIRST_OFFER = 'FIRST_OFFER',
  TIME_OFFER = 'TIME_OFFER',
}

/* -------------
List Rebate Offers
-------------- */
export async function getRebateOffers(params = {}, signal?: AbortSignal) {
  return baseAPICall<PaginatedRebateOffers>(() =>
    clientInstance.get('tradespend/firstbite/offers/', {
      signal,
      params,
    })
  )
}

export const useGetRebateOffers = (options: GetRebateOffersOptions) =>
  useQuery({
    queryKey: ['rebate-offers', options],
    queryFn: () => getRebateOffers(options),
    refetchOnMount: false,
  })

/* -------------
Get Single Rebate Offer
-------------- */
export async function getRebateOffer(offerId: number) {
  return baseAPICall<RebateOffer>(() =>
    clientInstance.get(`tradespend/firstbite/offers/${offerId}/`)
  )
}

export const useGetRebateOffer = (offerId: number) =>
  useQuery({
    queryKey: ['rebate-offer', offerId],
    queryFn: () => getRebateOffer(offerId),
    enabled: offerId !== undefined && offerId !== null,
  })

/* -------------
List Rebate Enrollments
-------------- */
export async function getRebateEnrollments(offerId: number) {
  return baseAPICall<PaginatedRebateEnrollments>(() =>
    clientInstance.get(`tradespend/firstbite/offers/${offerId}/enrollments/`)
  )
}

export const useGetRebateEnrollments = (offerId: number) =>
  useQuery({
    queryKey: ['rebate-enrollments', offerId],
    queryFn: () => getRebateEnrollments(offerId),
    enabled: !!offerId,
  })

/* -------------
List Rebate Claims
-------------- */
export async function getRebateClaims(params = {}, signal?: AbortSignal) {
  const { offerId, ...restParams } = params as {
    offerId?: number
    [key: string]: any
  }
  const endpoint = offerId
    ? `tradespend/firstbite/offers/${offerId}/claims/`
    : 'tradespend/firstbite/claims/'

  return baseAPICall<PaginatedRebateClaims>(() =>
    clientInstance.get(endpoint, {
      signal,
      params: {
        ...restParams,
      },
    })
  )
}

interface GetRebateClaimsOptions {
  offerId?: number
  signal?: AbortSignal
  page?: number
  limit?: number
  [key: string]: any
}

export const useGetRebateClaims = (options: GetRebateClaimsOptions) =>
  useQuery({
    queryKey: ['rebate-claims', options],
    queryFn: () => getRebateClaims(options, options.signal),
    refetchOnMount: false,
  })

/* -------------
Get Rebate Suggestion
-------------- */
type GetRebateSuggestionsBody = {
  campaign_id?: number
  type: string
  basis: string
  min: number
}

export async function getRebateSuggestions(values: GetRebateSuggestionsBody) {
  return baseAPICall<RebateOfferTerms>(() =>
    clientInstance.post('tradespend/firstbite/get-rebate-suggestions/', values)
  )
}

/* -------------
Create Rebate Offer
-------------- */
export interface CreateRebateOfferTermsVariant {
  variant_id: number
}

export interface CreateRebateOfferTermsProduct {
  product_id: number
  product_variants: CreateRebateOfferTermsVariant[]
}

export interface OfferTerms {
  tiers: Array<{
    min: number
    max: number | null
    discount: number
    discount_type: string
  }>
  basis: string
  period_type: string
  period_value: string
  period_year: string
  products: CreateRebateOfferTermsProduct[]
}

export interface CreateRebateOfferBody {
  name: string
  campaign_id?: number
  valid_from: Date
  valid_to: Date
  type: string
  terms: OfferTerms
}

export async function createRebateOffer(values: CreateRebateOfferBody) {
  return baseAPICall<RebateOfferTerms>(() =>
    clientInstance.post('tradespend/firstbite/create-rebate-offer/', values)
  )
}

/* -------------
Get Enrollment Details
-------------- */
interface ProductIdentifier {
  product_identifier: string
  product_identifier_type: string
}

interface ProductInfo {
  num_units: number
  product_identifiers: ProductIdentifier[]
}

interface VariantApprovalInfo {
  [variantId: string]: ProductInfo
}

interface ProductApprovalInfo {
  [productId: string]: VariantApprovalInfo
}

interface InvoiceApprovalInfo {
  business_name_matches: boolean
  order_confirmed: boolean
  address_valid: boolean
}

export interface RebateInvoice {
  id: number
  files: Array<{
    id: number
    file: string
  }>
  parsed_data: Record<string, any>
  product_approval_info: ProductApprovalInfo | null
  invoice_approval_info: InvoiceApprovalInfo | null
  invoice_reference_id: string
  created: string
}

export interface RebateClaimDetail {
  id: number
  created: string
  status: string
  payout_amount: number | null
  invoices: RebateInvoice[]
}

export interface RebateOfferProduct {
  product_id: number
  product_name: string
  product_desc: string
  product_variants: Array<{
    variant_id: number
    variant_sku: string
    variant_desc: string
    variant_gtin: string
    variant_name: string
    variant_format: string
  }>
}

export interface RebateEnrollmentDetail {
  id: number
  tradespend_user_name: string
  company_name: string
  terms: {
    unit: string
    tiers: Array<{
      max: number
      min: number
      discount: number
      discount_type: string
    }>
    products: RebateOfferProduct[]
    company_name: string
  }
  created: string
  total_claims: number
  pending_validation_claims: number
  invalid_claims: number
  paid_claims: number
  unpaid_valid_claims: number
  claims: RebateClaimDetail[]
}

export async function getRebateEnrollmentDetails(enrollmentId: number) {
  return baseAPICall<RebateEnrollmentDetail>(() =>
    clientInstance.get(`tradespend/firstbite/enrollments/${enrollmentId}/`)
  )
}

export const useGetRebateEnrollmentDetails = (enrollmentId: number) =>
  useQuery({
    queryKey: ['rebate-enrollment-details', enrollmentId],
    queryFn: () => getRebateEnrollmentDetails(enrollmentId),
    enabled: !!enrollmentId,
  })

export interface ManufacturerInvoice {
  id: number
  created: string
  amount: number
  status: 'DRAFT' | 'PENDING' | 'PAID' | 'OVERDUE'
  due_date: string
  claim_count: number
  company_name: string
  paid_at?: string
}

interface PaginatedManufacturerInvoices {
  results: ManufacturerInvoice[]
  count: number
}

/* -------------
List Manufacturer Invoices
-------------- */
export async function getManufacturerInvoices() {
  return baseAPICall<PaginatedManufacturerInvoices>(() =>
    clientInstance.get('tradespend/firstbite/invoices/')
  )
}

export function useManufacturerInvoices() {
  const query = useQuery({
    queryKey: ['manufacturer-invoices'],
    queryFn: () => getManufacturerInvoices(),
  })

  return {
    ...query,
  }
}

/* -------------
Get Dashboard Stats
-------------- */
export interface RebateDashboardStats {
  foodservice_chains: number
  claims_received: number
  rebate_value_processed: number
  redemption_rate: number
  claims_by_offer: Array<{
    offer_id: number
    offer_name: string
    count: number
    value: number
  }>
  claims_by_product: Array<{
    product_id: number
    product_name: string
    count: number
    value: number
  }>
  monthly_trends: {
    offers: string[]
    data: Array<{
      month: string
      [key: string]: string | { count: number; value: number }
    }>
  }
}

export async function getRebateDashboardStats(
  startDate?: string,
  endDate?: string
) {
  return baseAPICall<RebateDashboardStats>(() =>
    clientInstance.get('tradespend/firstbite/dashboard-stats/', {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    })
  )
}

export function useGetRebateDashboardStats(
  startDate?: string,
  endDate?: string
) {
  return useQuery({
    queryKey: ['rebate-dashboard-stats', startDate, endDate],
    queryFn: () => getRebateDashboardStats(startDate, endDate),
  })
}

export async function getRebateOfferOptions() {
  return baseAPICall<{ id: number; name: string }[]>(() =>
    clientInstance.get('tradespend/firstbite/offers/options/')
  )
}

export function useGetRebateOfferOptions() {
  return useQuery({
    queryKey: ['rebateOfferOptions'],
    queryFn: getRebateOfferOptions,
  })
}

/* -------------
Get Current Manufacturer Invoice
-------------- */
interface ManufacturerInvoiceItem {
  customer: string
  invoice: string
  amount: number
  currency: string
  description: string
  metadata: Record<string, any>
}

interface CurrentManufacturerInvoice {
  invoice_items_to_create: ManufacturerInvoiceItem[]
  total_amount: number
  check_fee: number
  pending_claims: RebateClaim[]
}

async function getCurrentManufacturerInvoice() {
  return baseAPICall<CurrentManufacturerInvoice>(() =>
    clientInstance.get('tradespend/firstbite/current-invoice/')
  )
}

export function useGetCurrentManufacturerInvoice() {
  return useQuery({
    queryKey: ['current-manufacturer-invoice'],
    queryFn: getCurrentManufacturerInvoice,
  })
}

export async function deleteRebateOffer(offerId: number) {
  return baseAPICall(() =>
    clientInstance.delete(`tradespend/firstbite/offers/${offerId}/`)
  )
}

export const useDeleteRebateOffer = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: deleteRebateOffer,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['rebate-offers'] })
    },
  })
}

export interface AttributionCode {
  id: number
  name: string
  description: string | null
  color: string | null
  code: string
  offer: number
  offer_code?: string
  created: string
  modified: string
  is_default: boolean
  created_by: {
    id: number
    email: string
    first_name: string
    last_name: string
  } | null
  assigned_by: {
    id: number
    email: string
    first_name: string
    last_name: string
  } | null
  view_count: number
  total_enrollments: number
  total_claims: number
  pending_claims: number
  invalid_claims: number
  paid_claims: number
  unpaid_valid_claims: number
  total_validated_units: number
  total_validated_payout: number
}

export interface AttributionCodeCreateRequest {
  offer_id: number
  name: string
  description?: string
  color?: string
}

export interface AttributionCodeUpdateRequest {
  name?: string
  description?: string | null
  color?: string | null
}

/* -------------
Attribution Code Endpoints
-------------- */

export async function getAttributionCodes(
  offerId: number,
  params = {},
  signal?: AbortSignal
) {
  return baseAPICall(() =>
    clientInstance.get(
      `tradespend/firstbite/offers/${offerId}/attribution-codes/`,
      {
        signal,
        params,
      }
    )
  )
}

export const useGetAttributionCodes = (offerId: number | null, params = {}) =>
  useQuery({
    queryKey: ['attribution-codes', offerId, params],
    queryFn: () => getAttributionCodes(offerId!, params),
    enabled: !!offerId,
  })

export async function createAttributionCode(
  data: AttributionCodeCreateRequest
) {
  try {
    const result = await baseAPICall<AttributionCode>(
      () =>
        clientInstance.post('tradespend/firstbite/attribution-codes/', data),
      { successMessage: 'Attribution code created successfully' }
    )
    return result
  } catch (error) {
    console.error('Error creating attribution code:', error)
    throw error
  }
}

export async function updateAttributionCode(
  id: number,
  data: AttributionCodeUpdateRequest
) {
  return baseAPICall<AttributionCode>(
    () =>
      clientInstance.put(`tradespend/firstbite/attribution-codes/${id}/`, data),
    { successMessage: 'Attribution code updated successfully' }
  )
}

export async function deleteAttributionCode(id: number) {
  return baseAPICall<void>(
    () =>
      clientInstance.delete(`tradespend/firstbite/attribution-codes/${id}/`),
    { successMessage: 'Attribution code deleted successfully' }
  )
}
