import { NoteManager } from 'components/Notes/NoteManager'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { INote } from '../../models/contact_companies'
import apiService from '../../services/api'
import { Modal } from '../UI/Modal/Modal'

export default function NotesModal(props: {
  noteToEdit?: INote
  show: boolean
  handleClose: () => void
  chainId: number | undefined
  contactCompanyId: number | undefined
  viewMode?: boolean
  onSubmitSuccess?: () => void
  onEditMode?: () => void
}) {
  const api = apiService()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isEditing, setIsEditing] = useState(!props.viewMode)

  const { control, handleSubmit } = useForm({
    defaultValues: {
      note: props.noteToEdit ? props.noteToEdit.content : '',
      attachments: [],
    },
  })

  const onSubmit = handleSubmit(async (values) => {
    setIsSubmitting(true)
    if (props.noteToEdit) {
      await api.editNote(props.noteToEdit.id, values.note)
      if (values.attachments.length > 0) {
        await api.addAttachmentsToNote(props.noteToEdit.id, values.attachments)
      }
    } else {
      await api.createNote(
        props.chainId,
        props.contactCompanyId,
        values.note,
        values.attachments
      )
    }
    setIsSubmitting(false)
    props.onSubmitSuccess?.()
    props.handleClose()
  })

  const handleEdit = () => {
    setIsEditing(true)
    props.onEditMode?.()
  }

  return (
    <Modal
      size="lg"
      open={props.show}
      onOpenChange={(open) => !open && props.handleClose()}
      title={
        props.viewMode && !isEditing
          ? 'View Note'
          : props.noteToEdit
            ? 'Edit Note'
            : 'Add a Note'
      }
      onAccept={props.viewMode && !isEditing ? handleEdit : onSubmit}
      acceptButtonText={
        props.viewMode && !isEditing
          ? 'Edit'
          : props.noteToEdit
            ? 'Save'
            : 'Add'
      }
      loading={isSubmitting}
      cancelButtonText={props.viewMode && !isEditing ? 'Close' : undefined}
      onCancel={props.viewMode && !isEditing ? props.handleClose : undefined}
    >
      <NoteManager
        control={control}
        noteName="note"
        attachmentsName="attachments"
        label=""
        existingNote={props.noteToEdit}
        viewMode={props.viewMode && !isEditing}
        editorClassName="h-64"
        enableMentions
      />
    </Modal>
  )
}
