import CommonPublicForm from './common-public-form'

export default function OptOutSuccess() {
  return (
    <div className="py-20 bg-gray-100 h-screen">
      <CommonPublicForm
        mainTitle="Opt Out of the Sale of Your Personal Information"
        description="Thank you for your submission. We have received your opt-out request and will process it accordingly."
      ></CommonPublicForm>
    </div>
  )
}
