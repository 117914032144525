import apiService from 'services/api'
import CommonPublicForm from './common-public-form'
import { BaseTextInput } from 'components/FormUtils/TextInput/text-input'
import FbButton from 'components/FbUI/FbButton'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { BaseCheckbox } from 'components/FormUtils/Checkbox/checkbox'

export default function OptOutForm() {
  const api = apiService()
  const { data } = api.useGetTextContent()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [checked, setChecked] = useState(false)

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsLoading(true)
    const formData = new FormData(e.currentTarget)

    try {
      await api.optOutSubmission({
        first_name: formData.get('first_name') as string,
        last_name: formData.get('last_name') as string,
        email: formData.get('email') as string,
        do_not_sell: checked,
      })
      navigate('/opt-out-success')
    } catch (error) {
      console.error('Error submitting form:', error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="py-20 bg-gray-100 h-full">
      <CommonPublicForm
        mainTitle="Opt Out of the Sale of Your Personal Information"
        formTitle="Opt Out Form"
        description={data?.opt_out_form}
      >
        <form onSubmit={handleSubmit}>
          <div className="flex gap-2">
            <BaseTextInput
              name="first_name"
              placeholder="First Name"
              className="w-full"
              required
            />
            <BaseTextInput
              name="last_name"
              placeholder="Last Name"
              className="w-full"
              required
            />
          </div>

          <div>
            <BaseTextInput
              name="email"
              type="email"
              placeholder="Email"
              className="w-full"
              required
            />
          </div>

          <div className="mt-3">
            <BaseCheckbox
              onChange={(e) => setChecked(e)}
              value={checked}
              name="do_not_sell"
              label="Do Not Sell My Personal Information"
              required
            />
          </div>

          <div className="mt-3">
            <FbButton
              disabled={!checked || isLoading}
              loading={isLoading}
              type="submit"
              className="w-full"
            >
              Opt Out
            </FbButton>
          </div>
        </form>
      </CommonPublicForm>
    </div>
  )
}
