import React, { ReactNode } from 'react'

interface CommonPublicFormProps {
  mainTitle: string
  description?: string
  formTitle?: string
  children?: ReactNode
}

const CommonPublicForm: React.FC<CommonPublicFormProps> = ({
  mainTitle,
  description,
  formTitle,
  children,
}) => {
  return (
    <div className="bg-white rounded-lg shadow-md p-6 max-w-2xl mx-auto">
      <div className="mb-8">
        <h1 className="my-3 text-center text-4xl font-bold text-gray-900">
          {mainTitle}
        </h1>
        <hr className="mb-12" />
        {description && (
          <div
            className="prose"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </div>

      {formTitle && (
        <h2 className="text-xl font-semibold text-gray-800 mb-2">
          {formTitle}
        </h2>
      )}
      {children}
    </div>
  )
}

export default CommonPublicForm
